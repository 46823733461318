import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';
import Color from 'color';
import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../../../assets/lottie/checkout-success.json';
import { Typography } from '../../common/Typography';
import { CheckoutLocalization } from '../configurations/textOverrides';

export const ANIMATION_DURATION = 5000;

const CheckoutSuccessContainer = styled(Box)`
  @keyframes blurFade {
    0% {
      background-color: ${({ theme }) => Color(theme.stigg.palette.backgroundPaper).alpha(0).toString()};
      backdrop-filter: blur(0px);
    }
    100% {
      background-color: ${({ theme }) => Color(theme.stigg.palette.backgroundPaper).alpha(0.9).toString()};
      backdrop-filter: blur(6.5px);
    }
  }

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  background-color: ${({ theme }) => Color(theme.stigg.palette.backgroundPaper).alpha(0.9).toString()};
  animation: blurFade 2s ease-in forwards;
  display: flex;
  flex-direction: column;
  justify-content: center;

  * rect {
    fill: transparent;
  }

  & path {
    stroke: ${({ theme }) => theme.stigg.palette.primary};
  }
`;

const CheckoutSuccessText = styled(Typography)`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  align-self: center;
  animation: fadeIn 5s ease-in forwards;
`;

const StyledLottie = styled(Lottie)`
  display: flex;
  justify-content: center;

  svg {
    width: 350px !important;
    height: auto !important;
  }
`;

export function CheckoutSuccess({ checkoutLocalization }: { checkoutLocalization: CheckoutLocalization }) {
  return (
    <CheckoutSuccessContainer className="stigg-checkout-success-container">
      <StyledLottie animationData={animationData} loop={false} autoplay />
      <CheckoutSuccessText variant="h1" color="primary.main">
        {checkoutLocalization.summary.checkoutSuccessText}
      </CheckoutSuccessText>
    </CheckoutSuccessContainer>
  );
}
