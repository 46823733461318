import { BillingModel, BillingPeriod, Currency, Price, TiersMode } from '@stigg/js-client-sdk';
import { currencyPriceFormatter } from '../../../utils/currencyUtils';
import { isBulkTiers, isQuantityInFirstTier } from '../../../utils/priceTierUtils';
import { numberFormatter } from '../../../utils/numberUtils';

export type GetPriceBreakdownStringProps = {
  totalAmount: number;
  quantity: number;
  tiersMode: Price['tiersMode'];
  tiers: Price['tiers'];
  currency: Currency;
  pricingModel: BillingModel;
  billingPeriod: BillingPeriod;
  blockSize?: number | null;
  priceAmount?: Price['amount'];
};

export function formatPricePerUnit({
  quantity,
  totalAmount,
  pricingModel,
  billingPeriod,
  currency,
  blockSize,
  priceAmount,
}: GetPriceBreakdownStringProps) {
  const isPerUnit = pricingModel === BillingModel.PerUnit;
  const aBlockSize = blockSize || 1;
  const featureUnits =
    quantity && (isPerUnit || quantity > 1) ? `${numberFormatter(quantity)} ${aBlockSize > 1 ? 'for' : 'x'} ` : '';
  const billingPeriodString = billingPeriod === BillingPeriod.Annually ? ' x 12 months' : '';

  const unitPrice = (priceAmount || totalAmount / quantity) / (billingPeriod === BillingPeriod.Annually ? 12 : 1);
  const formattedUnitPrice = currencyPriceFormatter({
    amount: unitPrice,
    currency,
    minimumFractionDigits: 2,
  });
  const formattedTotalPrice = currencyPriceFormatter({
    amount: totalAmount,
    currency,
    minimumFractionDigits: 2,
  });

  return `${featureUnits}${formattedUnitPrice}${billingPeriodString} ${
    billingPeriodString || featureUnits ? ` =  ${formattedTotalPrice}` : ''
  }`;
}

export function getPriceBreakdownString(params: GetPriceBreakdownStringProps) {
  const { totalAmount, quantity, tiersMode, tiers, currency } = params;
  if (isBulkTiers(tiers) || (tiersMode === TiersMode.Graduated && !isQuantityInFirstTier(tiers, quantity))) {
    const formattedTotalPrice = currencyPriceFormatter({
      amount: totalAmount,
      currency,
      minimumFractionDigits: 2,
    });
    return `${numberFormatter(quantity)} for ${formattedTotalPrice}`;
  }

  return formatPricePerUnit(params);
}
