import React from 'react';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import styled from '@emotion/styled/macro';

export type InformationTooltipProps = TooltipProps & { $padding?: number; $maxWidth?: number };

export const InformationTooltip = styled(
  ({ children, className, placement = 'top', ...props }: InformationTooltipProps) => (
    <Tooltip
      arrow
      placement={placement}
      {...props}
      classes={{ popper: className, tooltip: 'stigg-tooltip', arrow: 'stigg-tooltip-arrow' }}>
      <div>{children}</div>
    </Tooltip>
  ),
)(({ theme, $padding = 8, $maxWidth = 300 }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.stigg.palette.white,
    maxWidth: $maxWidth,
    boxShadow: theme.stigg.shadows.popover,
    padding: $padding,
    borderRadius: theme.stigg.border.radius,
    color: theme.stigg.palette.text.primary,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.stigg.palette.white,
  },
}));
