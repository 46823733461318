import { BillingAddress, Customer } from '@stigg/js-client-sdk';

import { useCheckoutContext } from '../CheckoutProvider';

export type PaymentStepState = {
  useNewPaymentMethod: boolean;
  errorMessage?: string;
  billingAddress?: BillingAddress;
  taxPercentage?: number;
};

type GetPaymentStepInitialStateProps = {
  customer?: Customer;
  taxPercentage?: number;
};

export function getPaymentStepInitialState({
  customer,
  taxPercentage,
}: GetPaymentStepInitialStateProps): PaymentStepState {
  return {
    useNewPaymentMethod: !customer?.paymentMethodDetails,
    taxPercentage,
  };
}

function useSetUseNewPaymentMethod() {
  const [, setState] = useCheckoutContext();

  return (useNewPaymentMethod: boolean) =>
    setState((draft) => {
      draft.paymentStep.useNewPaymentMethod = useNewPaymentMethod;
    });
}

function useSetErrorMessage() {
  const [, setState] = useCheckoutContext();

  return (errorMessage?: string) =>
    setState((draft) => {
      draft.paymentStep.errorMessage = errorMessage;
    });
}

function useSetBillingAddress() {
  const [, setState] = useCheckoutContext();

  return (billingAddress?: BillingAddress) =>
    setState((draft) => {
      draft.paymentStep.billingAddress = billingAddress;
    });
}

function usePaymentState() {
  const [{ paymentStep }] = useCheckoutContext();
  return paymentStep;
}

export function usePaymentStepModel() {
  const state = usePaymentState();

  return {
    ...state,
    setUseNewPaymentMethod: useSetUseNewPaymentMethod(),
    setErrorMessage: useSetErrorMessage(),
    setBillingAddress: useSetBillingAddress(),
  };
}
