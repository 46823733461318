import React from 'react';

import styled from '@emotion/styled/macro';
import MuiButton, { ButtonProps } from '@mui/material/Button';

export type StyledButtonProps = { $success?: boolean; $error?: boolean };

const StyledButton = styled(MuiButton, { shouldForwardProp: (prop) => !prop.startsWith('$') })<StyledButtonProps>`
  border-radius: 10px;
  text-transform: none;

  background-color: ${({ theme, $success, $error }) => {
    if ($success) {
      return theme.stigg.palette.success;
    }
    if ($error) {
      return theme.stigg.palette.error;
    }
    return '';
  }};

  &:hover {
    background-color: ${({ theme, $success, $error }) => {
      if ($success) {
        return theme.stigg.palette.successDark;
      }
      if ($error) {
        return theme.stigg.palette.errorDark;
      }
      return '';
    }};
  }
`;

export const Button = ({ variant = 'outlined', ...props }: ButtonProps & StyledButtonProps) => {
  return <StyledButton variant={variant} {...props} />;
};
