import {
  BillingPeriod,
  DiscountType,
  Plan,
  SubscriptionPreviewDiscount,
  SubscriptionPreviewTaxDetails,
  DiscountDurationType,
} from '@stigg/js-client-sdk';
import moment from 'moment';
import merge from 'lodash/merge';
import { DeepPartial } from '../../../types';
import { currencyPriceFormatter } from '../../utils/currencyUtils';

export type CheckoutLocalization = {
  changePlan: string;
  billingPeriodsTitle: string;
  addAddonText: string;
  newPaymentMethodText: string;
  newPaymentMethodCardTitle: string;
  newPaymentMethodBillingAddressTitle: string;
  summary: {
    title: string;
    planName: (params: { plan: Plan }) => string;
    addCouponCodeText: string;
    couponCodeTitle: string;
    addonsSectionTitle: string;
    discountsSectionTitle: string;
    onlyPayAsYouGoText: string;
    startsAtText: string;
    proratedTotalDueText: string;
    baseChargeText: string | ((params: { billingPeriod: BillingPeriod }) => string);
    totalText: string;
    totalDueText: string;
    totalBillingPeriodText: (params: { billingPeriod: BillingPeriod }) => string;
    discountText: (params: { discountDetails: SubscriptionPreviewDiscount; currency: string }) => string;
    appliedCreditsTitle: string;
    taxTitle: (params: { taxDetails: SubscriptionPreviewTaxDetails }) => string;
    checkoutSuccessText: string;
    payAsYouGoTooltipText: string;
    changesWillApplyAtEndOfBillingPeriod: string | ((params: { billingPeriodEnd: Date }) => string);
    creditsForUnusedTimeText: (params: { credits: string }) => string;
  };
  checkoutButton: {
    nextText: string;
    noChangesText: string;
    updateText: string;
    downgradeToFreeText: string;
    upgradeText: string;
  };
  downgradeToFree: {
    alertText: (params: { plan: Plan }) => string;
    freePlanHeader: (params: { plan: Plan }) => string;
    freePlanName: (params: { plan: Plan }) => string;
    freePlanPriceText: (params: { plan: Plan }) => string;
    paidPlanHeader: (params: { plan: Plan }) => string;
    paidPlanName: (params: { plan: Plan }) => string;
    paidPlanPriceText: (params: { plan: Plan; billingPeriod?: BillingPeriod }) => string;
  };
};

export function getResolvedCheckoutLocalize(
  localizeOverride?: DeepPartial<CheckoutLocalization>,
): CheckoutLocalization {
  const checkoutDefaultLocalization: CheckoutLocalization = {
    changePlan: 'Change',
    billingPeriodsTitle: 'Billing cycle',
    addAddonText: 'Add',
    newPaymentMethodText: 'New payment method',
    newPaymentMethodBillingAddressTitle: 'Billing address',
    newPaymentMethodCardTitle: 'Payment method',
    summary: {
      title: 'Order summary',
      planName: ({ plan }) => `${plan.displayName} plan`,
      addCouponCodeText: 'Add promotion code',
      couponCodeTitle: 'Promotion code',
      addonsSectionTitle: 'Add-ons',
      discountsSectionTitle: 'Discounts',
      onlyPayAsYouGoText: 'Varies by usage',
      startsAtText: 'Starts at ',
      proratedTotalDueText: 'Prorated charge today',
      baseChargeText: () => 'Base charge',
      totalText: 'Total',
      totalDueText: 'Total due today',
      totalBillingPeriodText: ({ billingPeriod }) => `Billed ${billingPeriod.toLowerCase()}`,
      appliedCreditsTitle: 'Applied credits',
      creditsForUnusedTimeText: ({ credits }) =>
        `Your account will be granted credits worth ${credits} for unused time, which will be automatically applied to future payments.`,
      discountText: ({ discountDetails, currency }) => {
        let discountText = '';
        if (discountDetails.type === DiscountType.Percentage) {
          discountText = `${discountDetails.value}% off`;
        } else {
          discountText = `${currencyPriceFormatter({ amount: discountDetails.value, currency })}`;
        }

        let discountTextPostfix = '';
        switch (discountDetails.durationType) {
          case DiscountDurationType.Once: {
            discountTextPostfix = ' once';
            break;
          }
          case DiscountDurationType.Repeating: {
            const prefix = discountDetails.type === DiscountType.Fixed ? ' per month' : '';
            discountTextPostfix = `${prefix} for ${discountDetails.durationInMonths} months`;

            break;
          }

          case DiscountDurationType.Forever:
          default: {
            discountTextPostfix = '';
          }
        }

        return `${discountText}${discountTextPostfix}`;
      },
      taxTitle: ({ taxDetails }) => `Tax (${taxDetails?.percentage}%)`,
      changesWillApplyAtEndOfBillingPeriod: ({ billingPeriodEnd }) =>
        `Your changes will take effect at the end of your current billing cycle on ${moment(billingPeriodEnd).format(
          'MMMM D, YYYY',
        )}.`,
      checkoutSuccessText: 'Changes applied',
      payAsYouGoTooltipText: 'Billed according to actual usage',
    },
    checkoutButton: {
      nextText: 'Next',
      noChangesText: 'No changes',
      updateText: 'Update subscription',
      downgradeToFreeText: 'Cancel subscription',
      upgradeText: 'Subscribe',
    },
    downgradeToFree: {
      alertText: () => `We’re sorry to see you cancel your paid subscription 😭`,
      freePlanHeader: () => 'New plan',
      freePlanName: ({ plan }) => `${plan.displayName}`,
      freePlanPriceText: () => 'Free',
      paidPlanHeader: () => 'Current plan',
      paidPlanName: ({ plan }) => `${plan.displayName}`,
      paidPlanPriceText: ({ billingPeriod }) =>
        `Paid plan${billingPeriod ? `, billed ${billingPeriod.toLowerCase()}` : ''}`,
    },
  };

  return merge(checkoutDefaultLocalization, localizeOverride);
}
