import { BillingPeriod, PriceTierFragment, PricingType } from '@stigg/js-client-sdk';
import { getPaidPriceText } from './getPaidPriceText';
import { PaywallPlan, PaywallLocalization } from '../paywall';

export type PlanPriceText = {
  price: string;
  unit?: string;
  tiers?: PriceTierFragment[] | null | undefined;
  tierUnits?: string;
};

export function getPlanPrice(
  plan: PaywallPlan,
  billingPeriod: BillingPeriod,
  paywallLocale: PaywallLocalization,
  locale: string,
  shouldShowMonthlyPriceAmount: boolean,
  perUnitQuantityByFeature?: Record<string, number>,
): PlanPriceText {
  switch (plan.pricingType) {
    case PricingType.Free:
      if (typeof paywallLocale.price.free === 'function') {
        return paywallLocale.price.free(plan.paywallCurrency);
      }

      return paywallLocale.price.free;
    case PricingType.Custom:
      return {
        price:
          typeof paywallLocale.price.custom === 'function'
            ? paywallLocale.price.custom({ plan, selectedBillingPeriod: billingPeriod })
            : paywallLocale.price.custom,
      };
    case PricingType.Paid: {
      const planPrices = plan.pricePoints.filter((pricePoint) => pricePoint.billingPeriod === billingPeriod);
      const paywallCalculatedPrice = plan.paywallCalculatedPricePoints?.find(
        (pricePoint) => pricePoint.billingPeriod === billingPeriod,
      );

      if (!planPrices?.length && !paywallCalculatedPrice) {
        return {
          price: paywallLocale.price.priceNotSet,
        };
      }

      const paidParams = {
        planPrices,
        paywallCalculatedPrice,
        selectedBillingPeriod: billingPeriod,
        perUnitQuantityByFeature: perUnitQuantityByFeature || {},
      };

      return paywallLocale.price.paid
        ? paywallLocale.price.paid({ ...paidParams, plan })
        : getPaidPriceText({ ...paidParams, locale, shouldShowMonthlyPriceAmount, paywallLocale });
    }
    default:
      return {
        price: '',
      };
  }
}
