import {
  BillingPeriod,
  PaywallCalculatedPricePoint,
  PaywallCurrency,
  Plan,
  Price,
  PriceTierFragment,
} from '@stigg/js-client-sdk';
import merge from 'lodash/merge';
import { DeepPartial } from '../../types';
import { PlanPriceText } from '../utils/getPlanPrice';
import { PaywallPlan } from './types';

export { PlanPriceText } from '../utils/getPlanPrice';

export type CurrentPlanParams = {
  plan: Plan;
  selectedBillingPeriod: BillingPeriod;
};

export type PaywallLocalization = {
  highlightChip: string;
  entitlementsTitle?: (plan: PaywallPlan) => string;
  planCTAButton: {
    upgrade: string | ((plan: PaywallPlan) => string);
    downgrade: string | ((plan: PaywallPlan) => string);
    startTrial: (plan: PaywallPlan) => string;
    custom: ((data: CurrentPlanParams) => string) | string;
    currentPlan: string;
    startNew: string;
    switchToBillingPeriod: (billingPeriod: BillingPeriod) => string;
    cancelScheduledUpdate: string;
  };
  price: {
    startingAtCaption: string;
    billingPeriod?: (billingPeriod: BillingPeriod) => string;
    pricePeriod: (billingPeriod: BillingPeriod) => string;
    custom: ((data: CurrentPlanParams) => string) | string;
    priceNotSet: string;
    free: PlanPriceText | ((currency?: PaywallCurrency) => PlanPriceText);
    paid?: (priceData: {
      planPrices: Price[];
      paywallCalculatedPrice?: PaywallCalculatedPricePoint;
      plan: Plan;
      selectedBillingPeriod: BillingPeriod;
      selectedTier?: PriceTierFragment;
    }) => PlanPriceText;
  };
};

export function getResolvedPaywallLocalize(localizeOverride?: DeepPartial<PaywallLocalization>) {
  const paywallDefaultLocalization: PaywallLocalization = {
    highlightChip: 'Recommended',
    planCTAButton: {
      upgrade: 'Upgrade',
      downgrade: 'Downgrade',
      startTrial: (plan: PaywallPlan) => `Start ${plan.defaultTrialConfig?.duration} day trial`,
      custom: 'Contact us',
      currentPlan: 'Current plan',
      startNew: 'Get started',
      switchToBillingPeriod: (billingPeriod) => {
        return billingPeriod === BillingPeriod.Monthly ? 'Switch to monthly billing' : 'Switch to annual billing';
      },
      cancelScheduledUpdate: 'Cancel',
    },
    price: {
      startingAtCaption: 'Starts at',
      pricePeriod: (billingPeriod: BillingPeriod) => (billingPeriod === BillingPeriod.Monthly ? '/ month' : '/ year'),
      free: (currency) => ({
        price: `${currency?.symbol}0`,
      }),
      custom: 'Custom',
      priceNotSet: 'Price not set',
    },
  };
  return merge(paywallDefaultLocalization, localizeOverride);
}
