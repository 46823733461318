import isUndefined from 'lodash/isUndefined';
import React from 'react';
import { BillingModel, CustomerPortalEntitlement } from '@stigg/js-client-sdk';
import { CustomerPortalSubscriptionPriceFragment } from '@stigg/api-client-js/src/generated/sdk';
import { Typography } from '../../../common/Typography';
import { useCustomerPortalContext } from '../../CustomerPortalProvider';
import { getResetUsageText, getUsagePercentage } from './FeatureUsage.helper';
import { getFeatureDisplayName } from '../../../utils/getFeatureName';
import { FeatureUsageContainer } from './FeatureUsage.style';
import { FeatureUsageProgressBar } from './FeatureUsageProgressBar';
import { Icon } from '../../../common/Icon';
import { InformationTooltip } from '../../../common/InformationTooltip';
import { LongText } from '../../../common/LongText';
import { EntitlementCtaButton } from './EntitlementCTAButton';
import { OnBuyMoreCallbackFn } from '../../types';
import { OnManageClick } from '../../CustomerPortalContainer';

export type UsageBoxProps = {
  entitlement: CustomerPortalEntitlement;
  subscriptionPrice: CustomerPortalSubscriptionPriceFragment | undefined;
  onManageSubscription: OnManageClick | undefined;
  onBuyMore?: OnBuyMoreCallbackFn;
  hasCustomSubscription: boolean;
  canUpgradeSubscription: boolean;
};

export function FeatureUsage({
  entitlement,
  subscriptionPrice,
  onManageSubscription,
  onBuyMore,
  hasCustomSubscription,
  canUpgradeSubscription,
}: UsageBoxProps) {
  const { theme } = useCustomerPortalContext();

  const hasOverageUsage = !entitlement.hasUnlimitedUsage && entitlement.currentUsage! > entitlement.usageLimit!;

  if (!entitlement.feature) {
    return null;
  }

  return (
    <FeatureUsageContainer $highlight={!!subscriptionPrice} $borderColor={theme.borderColor}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 16 }}>
        <LongText variant="body1">{entitlement.feature && getFeatureDisplayName(entitlement.feature)}</LongText>
        {subscriptionPrice && (
          <InformationTooltip
            title={
              <Typography variant="body1">
                {subscriptionPrice.billingModel === BillingModel.PerUnit
                  ? 'Billed for committed usage'
                  : 'Billed for actual usage'}
              </Typography>
            }>
            <Icon icon="DollarCoin" svgStrokeColor={theme.iconsColor} />
          </InformationTooltip>
        )}
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 8 }}>
        <LongText
          variant="body1"
          color={hasOverageUsage ? 'error' : 'primary'}
          data-testid={`usage-data-${entitlement.feature?.refId}`}>
          {entitlement.currentUsage?.toLocaleString()}
          {!entitlement.hasUnlimitedUsage &&
            ` / ${entitlement.usageLimit?.toLocaleString()} (${getUsagePercentage(
              entitlement.currentUsage || 0,
              entitlement.usageLimit!,
            )}%)`}
        </LongText>

        <EntitlementCtaButton
          entitlement={entitlement}
          feature={entitlement.feature}
          onBuyMore={onBuyMore}
          canBuyMore={!!subscriptionPrice}
          onManageSubscription={onManageSubscription}
          canUpgradeSubscription={canUpgradeSubscription}
          hasCustomSubscription={hasCustomSubscription}
        />
      </div>

      <FeatureUsageProgressBar entitlement={entitlement} />

      {!entitlement.hasUnlimitedUsage &&
      !isUndefined(entitlement.usageLimit) &&
      !isUndefined(entitlement.currentUsage) &&
      entitlement.resetPeriod &&
      entitlement.usagePeriodEnd ? (
        <Typography color="secondary">{getResetUsageText(entitlement)}</Typography>
      ) : null}
      {entitlement.hasUnlimitedUsage && <Typography color="secondary">In current billing period</Typography>}
    </FeatureUsageContainer>
  );
}
