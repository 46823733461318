import styled from '@emotion/styled/macro';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Button, { buttonClasses } from '@mui/material/Button';
import { Icon } from '../../common/Icon';

export const StyledProgress = styled(LinearProgress, { shouldForwardProp: (prop) => !prop.startsWith('$') })<{
  $disabled?: boolean;
}>(({ theme, $disabled }) => ({
  [`&.${linearProgressClasses.root}`]: {
    borderRadius: theme.stigg.border.radius,
    backgroundColor: theme.stigg.palette.outlinedBorder,
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: $disabled ? theme.stigg.palette.primaryLight : theme.stigg.palette.primary,
  },
}));

export const StyledStepButton = styled(Button)(() => ({
  [`&.${buttonClasses.root}`]: {
    textTransform: 'none',
    lineHeight: 'inherit',
    justifyContent: 'flex-start',
    padding: '8px 4px',
  },
}));

export const StyledIcon = styled(Icon, { shouldForwardProp: (prop) => !prop.startsWith('$') })<{
  $disabled?: boolean;
  $shouldFill?: boolean;
}>(({ theme, $disabled, $shouldFill }) => ({
  circle: {
    stroke: $disabled ? theme.stigg.palette.primaryLight : theme.stigg.palette.primary,
    fill: $shouldFill ? ($disabled ? theme.stigg.palette.primaryLight : theme.stigg.palette.primary) : undefined,
  },
}));
