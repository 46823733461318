import styled from '@emotion/styled/macro';
import Grid from '@mui/material/Grid';
import ReactSkeleton from 'react-loading-skeleton';

export const SkeletonsContainer = styled(Grid, { shouldForwardProp: (prop) => !prop.startsWith('$') })<{
  $gap: number;
  $flexDirection?: 'row' | 'column';
}>`
  display: flex;
  flex-direction: ${({ $flexDirection }) => $flexDirection || 'row'};
  gap: ${({ $gap }) => $gap}px;
`;

export const FlexedSkeletonContainer = styled(SkeletonsContainer)`
  span {
    display: flex;
    flex: 1;
  }
`;

export const FlexedSkeleton = styled(Grid)<{ flex: number }>`
  span {
    display: flex;
    flex: ${({ flex }) => flex};
  }
`;

export const Skeleton = styled(ReactSkeleton)`
  border-radius: 4px;
`;
