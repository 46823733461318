import { CustomerPortalConfiguration } from '@stigg/js-client-sdk';
import { StiggTheme } from '../../theme/types';
import { DeepPartial } from '../../types';

export type CustomerPortalTheme = {
  sectionTitleColor: string;
  planNameColor: string;
  backgroundColor: string;
  borderColor: string;
  listItemBackgroundColor: string;
  tabMaxHeight: string;
  iconsColor?: string;
  iconsBackgroundColor?: string;
};

export function getResolvedCustomerPortalTheme(
  globalTheme: StiggTheme,
  themeOverride?: DeepPartial<CustomerPortalTheme>,
  remoteConfiguration?: CustomerPortalConfiguration | null,
) {
  const customerPortalDefaultTheme: CustomerPortalTheme = {
    sectionTitleColor: themeOverride?.sectionTitleColor || globalTheme.palette.text.secondary,
    planNameColor: themeOverride?.planNameColor || globalTheme.palette.text.primary,
    backgroundColor: themeOverride?.backgroundColor || remoteConfiguration?.palette?.backgroundColor || 'white',
    borderColor: themeOverride?.borderColor || remoteConfiguration?.palette?.borderColor || 'rgba(0, 30, 108, 0.15)',
    listItemBackgroundColor: themeOverride?.backgroundColor || remoteConfiguration?.palette?.backgroundColor || 'white',
    tabMaxHeight: themeOverride?.tabMaxHeight || '380px',

    // add icons background color and apply to scheduled updates and customer support icons
    iconsColor: themeOverride?.iconsColor || remoteConfiguration?.palette?.iconsColor || undefined,
    iconsBackgroundColor: themeOverride?.iconsBackgroundColor,
  };

  return customerPortalDefaultTheme;
}
