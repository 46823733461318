import React from 'react';
import styled from '@emotion/styled/macro';
import Switch from 'react-switch';
import { BillingPeriod } from '@stigg/js-client-sdk';
import { Typography } from '../common/Typography';

const PickerContainer = styled.div`
  height: 40px;
  padding: 0 20px;
  margin-bottom: ${({ theme }) => theme.stigg.layout.switchBottomSpacing};
  border-radius: 10px;
  border: ${({ theme }) => `1px solid ${theme.stigg.palette.outlinedBorder}`};
  background-color: ${({ theme }) => theme.stigg.palette.backgroundPaper};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PeriodText = styled(Typography)`
  margin: 0 8px;
`;

const StyledSwitch = styled(Switch)`
  & .react-switch-bg {
    border: ${({ theme }) => `1px solid ${theme.stigg.palette.switchBorder}`};
    background: ${({ theme }) => theme.stigg.palette.switchFill} !important;
  }

  & .react-switch-handle {
    box-shadow: ${({ theme }) => theme.stigg.shadows.light};
    background: ${({ theme }) => theme.stigg.palette.switchHandle} !important;
    top: 0.5px !important;
  }
`;

function DiscountRate({ discount, disabled }: { discount: number; disabled: boolean }) {
  return (
    <Typography variant="h6" color={disabled ? 'disabled' : 'primary'} className="stigg-discount-rate-text">
      {discount}% off
    </Typography>
  );
}

export function BillingPeriodPicker({
  discountRate,
  selectedBillingPeriod,
  onBillingPeriodChanged,
  availableBillingPeriods,
}: {
  discountRate: number;
  selectedBillingPeriod: BillingPeriod;
  onBillingPeriodChanged: (billingPeriod: BillingPeriod) => void;
  availableBillingPeriods: BillingPeriod[];
}) {
  if (availableBillingPeriods.length <= 1) {
    return null;
  }

  const isMonthlyBillingPeriod = selectedBillingPeriod === BillingPeriod.Monthly;

  return (
    <PickerContainer className="stigg-period-picker-container">
      <PeriodText
        variant="h6"
        className="stigg-monthly-period-text"
        color={isMonthlyBillingPeriod ? 'primary' : 'disabled'}>
        Monthly
      </PeriodText>
      <StyledSwitch
        className="stigg-period-switch"
        uncheckedIcon={false}
        checkedIcon={false}
        height={16}
        width={33}
        onChange={(isAnnuallySelected) =>
          onBillingPeriodChanged(isAnnuallySelected ? BillingPeriod.Annually : BillingPeriod.Monthly)
        }
        checked={!isMonthlyBillingPeriod}
        borderRadius={10}
        handleDiameter={19}
      />
      <PeriodText
        variant="h6"
        className="stigg-annual-period-text"
        color={!isMonthlyBillingPeriod ? 'primary' : 'disabled'}>
        Annual
      </PeriodText>
      {discountRate !== 0 && <DiscountRate discount={discountRate} disabled={isMonthlyBillingPeriod} />}
    </PickerContainer>
  );
}
