import { PriceTierFragment, TiersMode } from '@stigg/js-client-sdk';
import React from 'react';
import styled from '@emotion/styled/macro';
import { hasTierWithUnitPrice } from '../utils/priceTierUtils';
import { Typography } from './Typography';
import { VolumePerUnitInput } from './VolumePerUnitInput';
import { VolumeBulkSelect } from './VolumeBulkSelect';

export type TiersSelectContainerProps = {
  componentId: string;
  tiers?: PriceTierFragment[] | null;
  tierUnits?: string;
  tiersMode?: TiersMode | null;
  handleChange: (quantity: number) => void;
  value: number;
  width?: number;
};

const TiersSelectLayout = styled(Typography)`
  min-height: 46px;
`;

export function TiersSelectContainer(props: TiersSelectContainerProps) {
  const { tiers } = props;

  return (
    <TiersSelectLayout as="div" className="stigg-price-tier-select">
      {hasTierWithUnitPrice(tiers) ? <VolumePerUnitInput {...props} /> : <VolumeBulkSelect {...props} />}
    </TiersSelectLayout>
  );
}
