import React from 'react';
import styled from '@emotion/styled/macro';
import PoweredByStiggSvg from '../../assets/powered-by-stigg.svg';

export const STIGG_WATERMARK_CLASSNAME = 'stigg-watermark';

export type PoweredByStiggSources = 'paywall' | 'customer_portal' | 'checkout';

const PoweredByStiggThemedSvg = styled(PoweredByStiggSvg)`
  * {
    fill: ${({ theme }) => theme.stigg.palette.text.secondary};
  }
`;

type PoweredByStiggProps = {
  source: PoweredByStiggSources;
  showWatermark?: boolean;
  style?: React.CSSProperties;
  align?: 'center' | 'end';
};

const StyledPoweredByStigg = styled.div<{ $alignSelf?: string }>`
  align-self: ${({ $alignSelf }) => $alignSelf || 'unset'};

  @media (max-width: 1040px) {
    align-self: center;
  }
`;

export function PoweredByStigg({ source, showWatermark, align, style = {} }: PoweredByStiggProps) {
  if (!showWatermark) {
    return null;
  }

  const onWatermarkClick = () => {
    const sourceDomain = window.location.hostname.replace('www.', '');
    window.open(
      `https://www.stigg.io/?utm_source=${sourceDomain}&utm_medium=referral&utm_campaign=${source}`,
      '_blank',
    );
  };

  return (
    <StyledPoweredByStigg
      $alignSelf={align}
      className={STIGG_WATERMARK_CLASSNAME}
      style={{ cursor: 'pointer', ...style }}
      onClick={onWatermarkClick}>
      <PoweredByStiggThemedSvg />
    </StyledPoweredByStigg>
  );
}
