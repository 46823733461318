import { Price } from '@stigg/js-client-sdk';

export const getValidPriceQuantity = (price: Price, quantity: number) => {
  if (price.minUnitQuantity && quantity < price.minUnitQuantity) {
    return price.minUnitQuantity;
  }
  if (price.maxUnitQuantity && quantity > price.maxUnitQuantity) {
    return price.maxUnitQuantity;
  }
  return quantity;
};
