export interface Logger {
  log: (message: string, object?: Record<string, string>) => void;
  debug: (message: string, object?: Record<string, string>) => void;
  error: (message: string, error?: Error, ...params: Record<string, string>[]) => void;
}

export class LoggerService implements Logger {
  error(message: string, error?: Error, ...params: Record<string, string>[]): void {
    console.error(message, error || {}, params);
  }

  log(message: string, object?: Record<string, string>): void {
    console.log(message, object || {});
  }

  debug(message: string, object?: Record<string, string> | undefined): void {
    console.debug(message, object || {});
  }
}

// Export a singleton logger service, in the future we might allow to user to configure external logger
export default new LoggerService();
