import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';

export const CheckoutLayout = styled.div`
  margin: auto;
  width: 100%;
  min-height: 760px;
  max-width: 920px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;

  padding: 16px 32px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.stigg.palette.backgroundPaper};
  border: ${({ theme }) => `1px solid ${theme.stigg.palette.outlinedBorder}`};

  & * {
    box-sizing: border-box;
  }
`;
export const CheckoutContent = styled(Box)`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  flex-wrap: wrap;
  width: 100%;
`;

export const CheckoutPanel = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0;
  flex: 2;
`;
