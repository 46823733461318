import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import { Addon, BillingPeriod, SubscriptionAddon } from '@stigg/js-client-sdk';

import { Icon } from '../../../common/Icon';
import { Typography } from '../../../common/Typography';
import { currencyPriceFormatter } from '../../../utils/currencyUtils';
import { Button, InputField } from '../../components';
import { useAddonsStepModel } from '../../hooks/useAddonsStepModel';
import { usePlanStepModel } from '../../hooks/usePlanStepModel';
import { AddonListItemContainer, CheckoutAddonsContainer, TrashButton } from './CheckoutAddonsStep.style';
import { CheckoutLocalization } from '../../configurations/textOverrides';
import { useCheckoutModel, useProgressBarModel } from '../../hooks';
import { ON_WHEEL_BLUR } from '../../../utils/onWheelBlur';

type UseAddonsStepModel = ReturnType<typeof useAddonsStepModel>;

type AddonListItemProps = {
  addon: Addon;
  addonState?: SubscriptionAddon;
  initialAddonState?: SubscriptionAddon;
  billingPeriod: BillingPeriod;
  setAddon: UseAddonsStepModel['setAddon'];
  removeAddon: UseAddonsStepModel['removeAddon'];
  checkoutLocalization: CheckoutLocalization;
  onAddonsValidationChange: (params: { addonId: string; isValid: boolean }) => void;
  isValid: boolean;
};

function AddonListItem({
  addon,
  billingPeriod,
  addonState,
  initialAddonState,
  setAddon,
  removeAddon,
  checkoutLocalization,
  onAddonsValidationChange,
  isValid,
}: AddonListItemProps) {
  const addonPrice = addon.pricePoints.find((pricePoint) => pricePoint.billingPeriod === billingPeriod);
  const isAdded = !!addonState;
  const hasChanges =
    (!!addonState && !!initialAddonState && addonState.quantity !== initialAddonState.quantity) ||
    (!!initialAddonState && !addonState);

  const handleQuantityChange = (quantity: number | null) => {
    if (!quantity || quantity <= 0) {
      onAddonsValidationChange({ addonId: addon.id, isValid: false });
      // Reset the input value to null
      // @ts-ignore
      setAddon(addon, quantity ?? null);
      return;
    }

    onAddonsValidationChange({ addonId: addon.id, isValid: true });
    setAddon(addon, quantity);
  };

  const handleUndo = () => {
    if (initialAddonState) {
      setAddon(addon, initialAddonState.quantity);
    } else {
      removeAddon(addon.id);
    }

    onAddonsValidationChange({ addonId: addon.id, isValid: true });
  };

  return (
    <AddonListItemContainer container>
      <Grid item>
        <Typography variant="h6" lineHeight="24px">
          {addon.displayName}
        </Typography>
        {!!addonPrice && (
          <Typography variant="body1" lineHeight="20px" color="secondary">
            {`${currencyPriceFormatter({
              amount: addonPrice.amount!,
              currency: addonPrice.currency,
              minimumFractionDigits: 2,
            })}/${billingPeriod === BillingPeriod.Annually ? 'year' : 'month'}`}
          </Typography>
        )}
      </Grid>
      <Grid item>
        {hasChanges && (
          <Button variant="text" size="small" sx={{ padding: '8px', minWidth: 'unset' }} onClick={handleUndo}>
            <Typography color="primary.main" variant="body1">
              Undo
            </Typography>
          </Button>
        )}
        {isAdded && (
          <>
            <InputField
              id={`${addon.id}-input`}
              type="number"
              onWheel={ON_WHEEL_BLUR}
              sx={{ width: 120, marginX: 2 }}
              value={addonState?.quantity ?? ''}
              error={!isValid}
              helperText={!isValid ? 'Minimum 1' : undefined}
              FormHelperTextProps={{ sx: { margin: '4px' } }}
              onChange={(event) => handleQuantityChange(event?.target?.value ? Number(event?.target?.value) : null)}
            />
            <TrashButton
              color="error"
              onClick={() => {
                removeAddon(addon.id);
                onAddonsValidationChange({ addonId: addon.id, isValid: true });
              }}>
              <Icon icon="Trash" style={{ display: 'flex' }} />
            </TrashButton>
          </>
        )}
        {!isAdded && (
          <Button sx={{ paddingX: '22px', paddingY: '8px' }} onClick={() => handleQuantityChange(1)}>
            <Typography color="primary.main" variant="body1">
              {checkoutLocalization.addAddonText}
            </Typography>
          </Button>
        )}
      </Grid>
    </AddonListItemContainer>
  );
}

export function CheckoutAddonsStep() {
  const { checkoutLocalization, setIsValid } = useCheckoutModel();
  const { billingPeriod } = usePlanStepModel();
  const { setIsDisabled } = useProgressBarModel();
  const { initialAddons, addons, availableAddons, setAddon, removeAddon } = useAddonsStepModel();
  const [addonsValidation, setAddonsValidation] = useState(
    availableAddons?.reduce<Record<string, boolean>>((acc, curr) => {
      acc[curr.id] = true;
      return acc;
    }, {}) || {},
  );

  useEffect(() => {
    const isDisabled = Object.values(addonsValidation).some((x) => !x);
    setIsDisabled(isDisabled);
    setIsValid(!isDisabled);
  }, [addonsValidation, setIsDisabled, setIsValid]);

  return (
    <CheckoutAddonsContainer container>
      {availableAddons?.map((addon) => {
        const addonState = addons.find((x) => x.addon.id === addon.id);
        const initialAddonState = initialAddons?.find((x) => x.addon.id === addon.id);
        const isValid = addonsValidation[addon.id];

        return (
          <AddonListItem
            key={addon.id}
            addon={addon}
            billingPeriod={billingPeriod}
            addonState={addonState}
            initialAddonState={initialAddonState}
            setAddon={setAddon}
            removeAddon={removeAddon}
            checkoutLocalization={checkoutLocalization}
            onAddonsValidationChange={({ addonId, isValid }: { addonId: string; isValid: boolean }) =>
              setAddonsValidation({ ...addonsValidation, [addonId]: isValid })
            }
            isValid={isValid}
          />
        );
      })}
    </CheckoutAddonsContainer>
  );
}
