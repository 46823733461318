import { CheckoutConfiguration } from '@stigg/js-client-sdk';
import { StiggTheme } from '../../../theme/types';
import { DeepPartial } from '../../../types';

export type CheckoutTheme = {
  primary: string;
  textColor: string;
  backgroundColor: string;
  borderColor: string;
  summaryBackgroundColor: string;
  paymentInputBackgroundColor?: string;
  paymentInputBorderColor?: string;
  paymentInputBorderRadius?: string;
};

const defaultCheckoutTheme: CheckoutTheme = {
  primary: 'rgb(50, 126, 238)',
  textColor: 'rgb(0, 30, 108)',
  backgroundColor: 'rgb(255, 255, 255)',
  borderColor: 'rgb(235, 237, 243)',
  summaryBackgroundColor: 'rgb(109, 121, 144)',
};

export function getResolvedCheckoutTheme(
  globalTheme: StiggTheme,
  themeOverride?: DeepPartial<CheckoutTheme>,
  // all the remote colors override theme colors, no need to use them here
  _remoteConfiguration?: CheckoutConfiguration | null,
): CheckoutTheme {
  const { palette: globalPalette } = globalTheme || {};

  return {
    primary: themeOverride?.primary || globalPalette?.primary || defaultCheckoutTheme.primary,
    textColor: themeOverride?.textColor || globalPalette?.text.primary || defaultCheckoutTheme.textColor,
    backgroundColor:
      themeOverride?.backgroundColor || globalPalette?.backgroundPaper || defaultCheckoutTheme.backgroundColor,
    borderColor: themeOverride?.borderColor || globalPalette?.outlinedBorder || defaultCheckoutTheme.borderColor,
    summaryBackgroundColor:
      themeOverride?.summaryBackgroundColor ||
      globalPalette?.backgroundHighlight ||
      defaultCheckoutTheme.summaryBackgroundColor,
    paymentInputBackgroundColor: themeOverride?.paymentInputBackgroundColor,
    paymentInputBorderColor: themeOverride?.paymentInputBorderColor,
    paymentInputBorderRadius: themeOverride?.paymentInputBorderRadius,
  };
}
