import React from 'react';
import styled from '@emotion/styled/macro';
import { usePromotionCodeModel } from '../hooks/useCouponModel';
import { AddPromotionCode } from './AddPromotionCode';
import { AppliedPromotionCode } from './AppliedPromotionCode';
import { CheckoutLocalization } from '../configurations/textOverrides';
import { MockCheckoutPreviewCallback } from '../types';

const PromotionCodeSectionContainer = styled.div`
  margin: 16px 0;
`;

export type PromotionCodeSectionProps = {
  disabled: boolean;
  checkoutLocalization: CheckoutLocalization;
  onMockCheckoutPreview?: MockCheckoutPreviewCallback;
};

export const PromotionCodeSection = ({
  disabled,
  checkoutLocalization,
  onMockCheckoutPreview,
}: PromotionCodeSectionProps) => {
  const { promotionCode, setPromotionCode } = usePromotionCodeModel();

  const onClearPromotionCode = () => {
    setPromotionCode('');
  };

  const content = promotionCode ? (
    <AppliedPromotionCode promotionCode={promotionCode} onClearPromotionCode={onClearPromotionCode} />
  ) : (
    <AddPromotionCode
      disabled={disabled}
      checkoutLocalization={checkoutLocalization}
      onMockCheckoutPreview={onMockCheckoutPreview}
    />
  );

  return <PromotionCodeSectionContainer>{content}</PromotionCodeSectionContainer>;
};
