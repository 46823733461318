import { Theme } from '@emotion/react';

export type IconColor = 'primary' | 'secondary';

export const getIconColor = (color: IconColor | string | undefined, theme: Theme) => {
  switch (color) {
    case 'primary':
      return theme.stigg.palette.text.primary;
    case 'secondary':
      return theme.stigg.palette.text.secondary;
    default:
      return color;
  }
};
