import { Currency, CustomerPortalEntitlement } from '@stigg/js-client-sdk';
import React from 'react';
import { FeatureFragment } from '@stigg/api-client-js/src/generated/sdk';
import { Typography } from '../../../common/Typography';
import { currencyPriceFormatter } from '../../../utils/currencyUtils';
import { FeatureUsageProgressBar } from '../../usage/featureUsage/FeatureUsageProgressBar';
import { LongText } from '../../../common/LongText';
import { EntitlementCtaButton } from '../../usage/featureUsage/EntitlementCTAButton';
import { OnBuyMoreCallbackFn } from '../../types';
import { OnManageClick } from '../../CustomerPortalContainer';

export type UsageBasedChargeProps = {
  entitlement: Exclude<CustomerPortalEntitlement, 'feature'>;
  feature: FeatureFragment;
  amount: number;
  currency: Currency;
  onBuyMore?: OnBuyMoreCallbackFn;
  onManageSubscription: OnManageClick;
  canUpgradeSubscription: boolean;
  hasCustomSubscription: boolean;
  hideChargePrice?: boolean;
};

export function ChargeItem({
  amount,
  currency,
  entitlement,
  feature,
  hideChargePrice,
  onBuyMore,
  onManageSubscription,
  canUpgradeSubscription,
  hasCustomSubscription,
}: UsageBasedChargeProps) {
  return (
    <div
      className={`stigg-charge-list-item stigg-charge-list-item-${entitlement.feature!.refId}`}
      style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: 12 }}>
        <div style={{ display: 'flex', gap: 12 }}>
          <LongText variant="body1" color="primary">
            {feature.displayName}
          </LongText>
          {!hideChargePrice && (
            <LongText variant="body1" color="secondary">
              {currencyPriceFormatter({ amount, currency })} per {feature.featureUnits}
            </LongText>
          )}
        </div>

        <div style={{ display: 'flex', gap: 12 }}>
          <EntitlementCtaButton
            entitlement={entitlement}
            feature={feature}
            canBuyMore
            onBuyMore={onBuyMore}
            onManageSubscription={onManageSubscription}
            canUpgradeSubscription={canUpgradeSubscription}
            hasCustomSubscription={hasCustomSubscription}
          />
          <Typography variant="body1" color="primary" style={{ whiteSpace: 'nowrap' }}>
            {entitlement.hasUnlimitedUsage
              ? entitlement.currentUsage?.toLocaleString()
              : `${entitlement.currentUsage?.toLocaleString()} of ${entitlement.usageLimit?.toLocaleString()}`}
          </Typography>
        </div>
      </div>

      <FeatureUsageProgressBar entitlement={entitlement} />
    </div>
  );
}
