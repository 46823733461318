import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';

export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #001e6c;
  }
`;

export const TabsLayout = styled.div`
  width: 100%;
  flex: 1;
  margin-top: 16px;
`;

export const TabContent = styled(Box, { shouldForwardProp: (prop) => !prop.startsWith('$') })<{ $maxHeight: string }>`
  margin-top: 16px;
  max-height: ${({ $maxHeight }) => $maxHeight};
  overflow-y: auto;
`;

export const ListBox = styled.div<{ $backgroundColor: string; $borderColor: string }>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: ${({ $borderColor }) => `1px solid ${$borderColor}`};
  border-radius: 10px;
  margin-bottom: 16px;
  row-gap: 16px;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`;
