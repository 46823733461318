import { BillingPeriod } from '@stigg/js-client-sdk';
import { PaywallPlan } from '../types';

export function hasPricePoints(plan: PaywallPlan, billingPeriod: BillingPeriod): boolean {
  return plan.pricePoints.some((pricePoint) => pricePoint.billingPeriod === billingPeriod);
}

export function hasPricePointsForPlans(plans: PaywallPlan[], billingPeriod: BillingPeriod): boolean {
  return plans.some((plan) => hasPricePoints(plan, billingPeriod));
}
