import { FeatureFragment } from '@stigg/api-client-js/src/generated/sdk';
import lowercase from 'lodash/lowerCase';

export function getFeatureDisplayNameText(
  featureDisplayName: string,
  featureUnits: string | undefined | null,
  featureUnitsPlural: string | undefined | null,
) {
  if (!featureUnits && !featureUnitsPlural) {
    return featureDisplayName;
  }

  const displayNameLowerCase = lowercase(featureDisplayName);
  if (
    displayNameLowerCase === lowercase(featureUnits || '') ||
    displayNameLowerCase === lowercase(featureUnitsPlural || '')
  ) {
    return featureDisplayName;
  }

  return `${featureDisplayName} (${featureUnitsPlural})`;
}

export function getFeatureDisplayName(feature: FeatureFragment) {
  return getFeatureDisplayNameText(feature.displayName, feature.featureUnits, feature.featureUnitsPlural);
}
