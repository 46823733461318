import { CustomerPortalEntitlement } from '@stigg/js-client-sdk';
import { FeatureFragment } from '@stigg/api-client-js/src/generated/sdk';
import Button from '@mui/material/Button';
import React from 'react';
import styled from 'styled-components';
import { getUsagePercentage, USAGE_PERCENTAGE_WARNING_THRESHOLD } from './FeatureUsage.helper';
import { Typography } from '../../../common/Typography';
import { CustomerPortalIntentionType, OnBuyMoreCallbackFn } from '../../types';
import { OnManageClick } from '../../CustomerPortalContainer';

const StyledButton = styled(Button)`
  text-transform: none;
  padding: 0;
  white-space: nowrap;
`;

export type EntitlementCtaButtonProps = {
  entitlement: Exclude<CustomerPortalEntitlement, 'feature'>;
  feature: FeatureFragment;
  onManageSubscription: OnManageClick | undefined;
  onBuyMore?: OnBuyMoreCallbackFn;
  canBuyMore: boolean;
  canUpgradeSubscription: boolean;
  hasCustomSubscription: boolean;
};

export function EntitlementCtaButton({
  entitlement,
  feature,
  onManageSubscription,
  onBuyMore,
  canBuyMore,
  canUpgradeSubscription,
  hasCustomSubscription,
}: EntitlementCtaButtonProps) {
  if (hasCustomSubscription || entitlement.hasUnlimitedUsage) {
    return null;
  }

  if (getUsagePercentage(entitlement.currentUsage || 0, entitlement.usageLimit!) < USAGE_PERCENTAGE_WARNING_THRESHOLD) {
    return null;
  }

  if (canBuyMore) {
    return onBuyMore ? (
      <StyledButton variant="text" onClick={() => onBuyMore(feature, entitlement)}>
        <Typography color="primary.main">Buy more</Typography>
      </StyledButton>
    ) : null;
  }

  if (canUpgradeSubscription) {
    return onManageSubscription ? (
      <StyledButton
        variant="text"
        onClick={() => onManageSubscription({ intentionType: CustomerPortalIntentionType.UPGRADE_PLAN })}>
        <Typography color="primary.main">Upgrade</Typography>
      </StyledButton>
    ) : null;
  }

  return null;
}
