import React from 'react';
import { useCustomerPortalContext } from '../CustomerPortalProvider';
import { SectionTitle } from '../common/SectionTitle';
import { ExternalLinkButton } from '../common/ExternalLinkButton';
import { SectionContainer } from '../common/SectionContainer';
import { SectionHeader } from '../common/SectionHeader';
import { SkeletonButton } from '../common/SkeletonButton';

export function InvoicesSection() {
  const { customerPortal, isLoading, textOverrides, theme } = useCustomerPortalContext();
  const isLoadingData = !customerPortal || isLoading;

  const viewInvoiceHistoryButton = isLoadingData ? (
    <SkeletonButton />
  ) : customerPortal?.billingPortalUrl ? (
    <ExternalLinkButton
      url={customerPortal.billingPortalUrl}
      text={textOverrides.viewInvoiceHistory}
      className="stigg-view-invoice-history-button"
    />
  ) : null;

  if (!isLoadingData && !customerPortal?.billingPortalUrl) {
    return null;
  }

  return (
    <SectionContainer
      className="stigg-invoices-section-layout"
      $backgroundColor={theme.backgroundColor}
      $borderColor={theme.borderColor}>
      <SectionHeader className="stigg-invoices-section-header">
        <SectionTitle
          isLoading={isLoadingData}
          className="stigg-invoices-section-title"
          title={textOverrides.invoicesTitle}
        />
        {viewInvoiceHistoryButton}
      </SectionHeader>
    </SectionContainer>
  );
}
