import Color from 'color';
import merge from 'lodash/merge';
import { HorizontalAlignment, StiggTheme } from './types';
import { CustomizedTheme } from './Theme';

export const flexLayoutMapper = (horizontalAlignment: HorizontalAlignment) => {
  return {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  }[horizontalAlignment];
};

export const getResolvedTheme = (customizedTheme?: CustomizedTheme): StiggTheme => {
  const primaryColor = Color(customizedTheme?.palette?.primary || '#327EEE');
  const textColor = Color(customizedTheme?.palette?.text?.primary || '#001E6C');
  const successColor = Color('#4CAF50');
  const errorColor = Color('#f44336');

  const baseTheme: StiggTheme = {
    palette: {
      primary: primaryColor.hex(),
      primaryDark: primaryColor.darken(0.3).hex(),
      primaryLight: primaryColor.alpha(0.5).toString(),
      backgroundPaper: '#FFFFFF',
      backgroundHighlight: '#F5F6F9',
      backgroundSection: primaryColor.alpha(0.1).toString(),
      backgroundButton: primaryColor.alpha(0.08).toString(),
      outlinedBorder: '#C4CBDD',
      outlinedRestingBorder: primaryColor.alpha(0.5).toString(),
      outlinedHoverBackground: primaryColor.lightness(95).hex(),
      switchBorder: primaryColor.lighten(0.1).hex(),
      switchFill: primaryColor.lighten(0.5).hex(),
      switchHandle: primaryColor.hex(),
      warning: '#ed6c02',
      error: errorColor.hex(),
      errorDark: errorColor.darken(0.3).hex(),
      success: successColor.hex(),
      successDark: successColor.darken(0.3).hex(),
      white: '#FFFFFF',
      text: {
        primary: textColor.hex(),
        secondary: textColor.alpha(0.75).toString(),
        tertiary: textColor.alpha(0.5).toString(),
        disabled: textColor.alpha(0.35).toString(),
      },
    },
    layout: {
      planMinWidth: '330px',
      planMaxWidth: '330px',
      planMargin: '20px',
      planPadding: '40px',
      ctaAlignment: 'left',
      headerAlignment: 'left',
      descriptionAlignment: 'left',
      descriptionMinHeight: '80px',
      switchBottomSpacing: '40px',
    },
    shadows: {
      light: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
      popover:
        '0px 4px 5px -2px rgba(0, 30, 108, 0.2), 0px 7px 10px 1px rgba(0, 30, 108, 0.14), 0px 2px 16px 1px rgba(0, 30, 108, 0.12)',
    },
    border: {
      radius: '10px',
    },
    typography: {
      fontFamily: 'custom-font, DM Sans, sans-serif',
      h1: {
        fontSize: '32px',
        fontWeight: 'bold',
      },
      h2: {
        fontSize: '24px',
        fontWeight: 'normal',
      },
      h3: {
        fontSize: '16px',
        fontWeight: 'normal',
      },
      body: {
        fontSize: '14px',
        fontWeight: 'normal',
      },
      caption: {
        fontSize: '12px',
        fontWeight: 'normal',
      },
    },
    customCss: '',
  };

  return merge(baseTheme, customizedTheme);
};
