import sortBy from 'lodash/sortBy';
import { Addon, BillingPeriod, Currency, SubscriptionAddon } from '@stigg/js-client-sdk';

type FilterAddonsBaseProps = {
  billingPeriod: BillingPeriod;
  currency: Currency;
  billingCountryCode?: string;
};

export const sortAddons = (addons: Addon[]) => {
  return sortBy(addons, 'displayName');
};

export const sortSubscriptionAddons = (addons: SubscriptionAddon[]) => {
  return sortBy(addons, 'addon.displayName');
};

function filterAddonPricePointsByBillingPeriod(addon: Addon, billingPeriod: BillingPeriod) {
  return addon.pricePoints.some((pricePoint) => pricePoint.billingPeriod === billingPeriod);
}

function mapAddonPricePointsByBillingCountryCode({
  addon,
  currency,
  billingCountryCode,
}: {
  addon: Addon;
  currency: Currency;
  billingCountryCode?: string;
}) {
  return {
    ...addon,
    pricePoints: addon.pricePoints.filter(
      (pricePoint) =>
        pricePoint.currency === currency &&
        (billingCountryCode ? pricePoint.billingCountryCode === billingCountryCode : true),
    ),
  };
}

export function filterAddons({
  addons,
  billingPeriod,
  billingCountryCode,
  currency,
}: { addons?: Addon[] } & FilterAddonsBaseProps): Addon[] {
  return (
    addons
      ?.filter((addon) => filterAddonPricePointsByBillingPeriod(addon, billingPeriod))
      ?.map((addon) => mapAddonPricePointsByBillingCountryCode({ addon, currency, billingCountryCode })) || []
  );
}

export function filterSubscriptionAddons({
  addons,
  billingPeriod,
  billingCountryCode,
  currency,
}: { addons?: SubscriptionAddon[] } & FilterAddonsBaseProps): SubscriptionAddon[] {
  return (
    addons
      ?.filter((addon) => filterAddonPricePointsByBillingPeriod(addon.addon, billingPeriod))
      ?.map((addon) => ({
        ...addon,
        addon: mapAddonPricePointsByBillingCountryCode({ addon: addon.addon, currency, billingCountryCode }),
      })) || []
  );
}
