import { CustomerPortalEntitlement, CustomerPortalSubscription } from '@stigg/js-client-sdk';
import { FeatureFragment } from '@stigg/api-client-js/src/generated/sdk';

export type OnBuyMoreCallbackFn = (feature: FeatureFragment, entitlement: CustomerPortalEntitlement) => void;

export type FilterEntitlementsFn = (entitlements: CustomerPortalEntitlement[]) => CustomerPortalEntitlement[];

export enum CustomerPortalIntentionType {
  MANAGE_SUBSCRIPTION = 'MANAGE_SUBSCRIPTION',
  UPGRADE_PLAN = 'UPGRADE_PLAN',
  UPGRADE_TRIAL_PLAN = 'UPGRADE_TRIAL_PLAN',
}

export type OnManageSubscriptionFn = ({
  intentionType,
  customerSubscriptions,
}: {
  intentionType: CustomerPortalIntentionType;
  customerSubscriptions: CustomerPortalSubscription[];
}) => Promise<void> | void;
