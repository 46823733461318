import { useEffect, useState } from 'react';

import { BillingVendorIdentifier } from '@stigg/js-client-sdk';
import { loadStripe, Stripe } from '@stripe/stripe-js';

import { useCheckoutModel } from '../../../hooks';

export function useStripeIntegration() {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const { checkoutState } = useCheckoutModel();
  const { billingIntegration, setupSecret } = checkoutState || {};

  useEffect(() => {
    if (billingIntegration) {
      const { billingIdentifier, credentials } = billingIntegration;
      if (billingIdentifier !== BillingVendorIdentifier.Stripe) {
        console.error('Currently only stripe integration is supported');
        return;
      }

      const { accountId, publicKey } = credentials;
      setStripePromise(loadStripe(publicKey, { stripeAccount: accountId }));
    }
  }, [billingIntegration]);

  return { stripePromise, setupIntentClientSecret: setupSecret };
}
