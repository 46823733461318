import React, { useState } from 'react';
import { CustomerPortal } from '@stigg/js-client-sdk';
import flatMap from 'lodash/flatMap';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { FontWeight } from 'styled-typography';
import { Typography } from '../../../common/Typography';
import { AddonsList } from './AddonsList';
import { Promotions } from './Promotions';
import { CustomerPortalSection } from '../../CustomerPortal';
import { CustomerPortalLocalization } from '../../customerPortalTextOverrides';
import { StyledTabs, TabContent, TabsLayout } from './SubscriptionTabs.style';
import { CustomerPortalTheme } from '../../customerPortalTheme';
import { Icon, Icons } from '../../../common/Icon';

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  maxHeight: string;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, maxHeight, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <TabContent $maxHeight={maxHeight}>{children}</TabContent>}
    </div>
  );
}

export type SubscriptionTabsProps = {
  customerPortal: CustomerPortal;
  hiddenSections?: CustomerPortalSection[];
  textOverrides: CustomerPortalLocalization;
  theme: CustomerPortalTheme;
};

function TabTitle({ isSelected, label, icon }: { isSelected: boolean; label: string; icon: Icons }) {
  const color = isSelected ? 'primary' : 'secondary';
  return (
    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
      <Icon icon={icon} svgStrokeColor={color} />
      <Typography variant="h6" bold={isSelected} fontWeight={FontWeight.Medium} color={color}>
        {label}
      </Typography>
    </div>
  );
}

export function SubscriptionTabs({ customerPortal, hiddenSections, textOverrides, theme }: SubscriptionTabsProps) {
  const allAddons = flatMap(customerPortal.subscriptions, (subscription) => subscription.addons);
  const isSectionHidden = (sectionName: CustomerPortalSection) =>
    hiddenSections?.some((section) => section === sectionName);
  const showAddons = allAddons.length > 0 && !isSectionHidden('addons');
  const showPromotions =
    customerPortal.promotionalEntitlements.length > 0 && !isSectionHidden('promotionalEntitlements');
  const [value, setValue] = useState(showAddons ? 1 : 2);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const shouldShowTabs = showAddons || showPromotions;

  if (!shouldShowTabs) {
    return null;
  }

  return (
    <TabsLayout>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <StyledTabs value={value} onChange={handleChange}>
          {showAddons ? (
            <Tab
              sx={{ textTransform: 'none' }}
              value={1}
              label={<TabTitle isSelected={value === 1} label={textOverrides.addonsTabTitle} icon="Addons" />}
            />
          ) : null}
          {showPromotions ? (
            <Tab
              sx={{ textTransform: 'none' }}
              value={2}
              label={<TabTitle isSelected={value === 2} label={textOverrides.promotionsTabTitle} icon="Promotions" />}
            />
          ) : null}
        </StyledTabs>
      </Box>
      <TabPanel value={value} index={1} maxHeight={theme.tabMaxHeight}>
        <AddonsList />
      </TabPanel>
      <TabPanel value={value} index={2} maxHeight={theme.tabMaxHeight}>
        <Promotions />
      </TabPanel>
    </TabsLayout>
  );
}
