import { BillingPeriod } from '@stigg/js-client-sdk';

export function formatBillingPeriod(billingPeriod: BillingPeriod) {
  switch (billingPeriod) {
    case BillingPeriod.Annually:
      return 'Annual';
    case BillingPeriod.Monthly:
      return 'Monthly';
    default:
      return '';
  }
}
