import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { CustomerPortalSubscription } from '@stigg/js-client-sdk';
import styled from '@emotion/styled/macro';
import { useTheme } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';
import { CustomerPortalTheme } from '../../customerPortalTheme';
import Restore from '../../../../assets/restore.svg';
import { Icon } from '../../../common/Icon';
import { Typography } from '../../../common/Typography';
import { SubscriptionScheduledUpdateRow } from './SubscriptionScheduledUpdateRow';
import { getSubscriptionScheduleUpdateTexts } from '../../../utils/getSubscriptionScheduleUpdateTexts';
import { StyledButton } from '../../common/StyledButton';

export type SubscriptionScheduledUpdatesAlertProps = {
  subscription: CustomerPortalSubscription;
  onCancelScheduledUpdates?: (subscription: CustomerPortalSubscription) => void;
  theme: CustomerPortalTheme;
  cancelScheduledUpdatesButtonTitle: string;
};

const SubscriptionScheduleUpdateAlertContainer = styled(Box)<{ $borderColor: string }>`
  border-bottom: ${({ $borderColor }) => `1px solid ${$borderColor}`};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin: -64px -64px 64px -64px;
  padding: 24px 32px;
`;

const LoadingIndicator = styled(ClipLoader)`
  margin-left: 4px;
`;

const StyledRestoreIcon = styled(Restore)`
  color: ${({ theme }) => theme.stigg.palette.primary};
  path {
    fill: ${({ theme }) => theme.stigg.palette.primary};
  }
`;

function MultipleScheduledUpdates({ subscription }: Pick<SubscriptionScheduledUpdatesAlertProps, 'subscription'>) {
  const { scheduledUpdates } = subscription;

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" gap={1}>
        <Grid>
          <Typography className="stigg-scheduled-updates-description" variant="body1">
            The following updates will take effect in the next billing cycle:
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        {(scheduledUpdates || []).map((scheduleUpdate) => (
          <SubscriptionScheduledUpdateRow
            key={scheduleUpdate.subscriptionScheduleType}
            scheduleUpdate={scheduleUpdate}
            subscription={subscription}
          />
        ))}
      </Grid>
    </>
  );
}

function SingleScheduledUpdate({ subscription }: Pick<SubscriptionScheduledUpdatesAlertProps, 'subscription'>) {
  const { scheduledUpdates } = subscription;
  const [scheduledUpdate] = scheduledUpdates || [];

  const { line1, line2 } = getSubscriptionScheduleUpdateTexts({
    scheduledUpdate,
    subscriptionPrices: subscription.prices,
    billingPeriodRange: subscription.billingPeriodRange,
    options: {
      postfix: 'at the end of the current billing period', // We can later remove this if unnecessary
    },
  });

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" gap={1}>
        <Grid>
          <Typography className="stigg-scheduled-updates-title" variant="body1" color="primary">
            {line1}
          </Typography>
        </Grid>
      </Grid>
      {line2 && (
        <Grid>
          <Typography className="stigg-scheduled-updates-subtitle" variant="body1" color="secondary">
            {line2}
          </Typography>
        </Grid>
      )}
    </>
  );
}

export function SubscriptionScheduledUpdatesAlert({
  subscription,
  onCancelScheduledUpdates,
  theme,
  cancelScheduledUpdatesButtonTitle,
}: SubscriptionScheduledUpdatesAlertProps) {
  const stiggTheme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { scheduledUpdates } = subscription;

  if (!scheduledUpdates || scheduledUpdates.length === 0) {
    return null;
  }

  const isSingleScheduledUpdate = scheduledUpdates.length === 1;

  return (
    <SubscriptionScheduleUpdateAlertContainer
      $borderColor={theme.borderColor}
      className="stigg-scheduled-updates-alert-container">
      <Grid container alignItems={isSingleScheduledUpdate ? 'center' : 'flex-start'} gap={2}>
        <Grid item display="flex">
          <Icon icon="ScheduleBox" svgPathColor={theme.iconsColor} svgRectColor={theme.iconsBackgroundColor} />
        </Grid>

        <Grid item flex={1}>
          {isSingleScheduledUpdate ? (
            <SingleScheduledUpdate subscription={subscription} />
          ) : (
            <MultipleScheduledUpdates subscription={subscription} />
          )}
        </Grid>

        {!!onCancelScheduledUpdates && (
          <Grid item>
            <StyledButton
              variant="outlined"
              onClick={() => {
                setIsLoading(true);
                Promise.resolve(onCancelScheduledUpdates(subscription)).finally(() => setIsLoading(false));
              }}>
              {isLoading ? (
                <LoadingIndicator loading size={16} color={stiggTheme.stigg.palette.text.disabled} />
              ) : (
                <StyledRestoreIcon
                  style={{ display: 'flex', marginRight: 4 }}
                  className="stigg-scheduled-updates-cancel-update-button-icon"
                />
              )}
              <Typography variant="body1" color="primary.main" className="stigg-scheduled-updates-cancel-update-button">
                {cancelScheduledUpdatesButtonTitle}
              </Typography>
            </StyledButton>
          </Grid>
        )}
      </Grid>
    </SubscriptionScheduleUpdateAlertContainer>
  );
}
