import React from 'react';
import { CheckoutContainerProps } from '../../CheckoutContainer';

import { useCheckoutModel } from '../../hooks/useCheckoutModel';
import { usePlanStepModel } from '../../hooks/usePlanStepModel';
import { PlanHeader } from '../../planHeader';
import { BillingPeriodPicker } from './BillingPeriodPicker';
import { CheckoutChargeList } from './CheckoutChargeList';
import { CheckoutPlanContainer } from './CheckoutPlanStep.style';

export const CheckoutPlanStep = ({ onChangePlan }: Pick<CheckoutContainerProps, 'onChangePlan'>) => {
  const { checkoutState, checkoutLocalization } = useCheckoutModel();
  const { plan } = checkoutState || {};
  const { billingPeriod } = usePlanStepModel();

  return (
    <>
      <PlanHeader allowChangePlan onChangePlan={onChangePlan} />
      <CheckoutPlanContainer>
        <BillingPeriodPicker plan={plan} checkoutLocalization={checkoutLocalization} />
        <CheckoutChargeList plan={plan} billingPeriod={billingPeriod} />
      </CheckoutPlanContainer>
    </>
  );
};
