import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { Typography } from '../../common/Typography';
import { useCheckoutModel } from '../hooks/useCheckoutModel';
import { CheckoutContainerProps } from '../CheckoutContainer';
import { ChangePlanButton } from '../components/ChangePlanButton';

type PlanHeaderProps = {
  allowChangePlan?: boolean;
} & Pick<CheckoutContainerProps, 'onChangePlan'>;

export function PlanHeader({ allowChangePlan = false, onChangePlan }: PlanHeaderProps) {
  const { checkoutState, checkoutLocalization } = useCheckoutModel();
  const { plan } = checkoutState || {};

  return (
    <>
      <Box sx={{ marginBottom: '16px' }}>
        <Typography variant="body1" color="disabled" style={{ opacity: 0.6, marginBottom: '8px' }}>
          Selected plan
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h1">{plan?.displayName}</Typography>

          {allowChangePlan && onChangePlan && (
            <ChangePlanButton
              onClick={() => {
                onChangePlan({ currentPlan: plan });
              }}
              checkoutLocalization={checkoutLocalization}
              size="medium"
            />
          )}
        </Box>
      </Box>
      <Divider className="stigg-checkout-plan-header-divider" />
    </>
  );
}
