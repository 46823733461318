import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { TooltipProps } from '@mui/material/Tooltip';
import { InformationTooltip } from './InformationTooltip';
import { Typography, TypographyProps } from './Typography';

const StyledText = styled(Typography)<{ $maxLines: number; $wordBreak?: boolean; $display: string; $bold?: boolean }>`
  display: ${({ $display }) => $display};
  -webkit-line-clamp: ${({ $maxLines }) => $maxLines};
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  ${({ $wordBreak }) =>
    $wordBreak &&
    css`
      word-break: break-word;
    `}

  ${({ $bold }) =>
    $bold &&
    css`
      font-weight: bold;
    `}
`;

function checkTextClamped(element: HTMLSpanElement) {
  return element && (element.clientHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth);
}

export const LongText = ({
  leaveDelay,
  children,
  variant,
  color,
  maxLines = 1,
  wordBreak,
  bold,
  tooltipPlacement = 'top',
  className,
  display = '-webkit-box',
  style,
  'data-testid': dataTestId,
}: {
  children: ReactNode;
  leaveDelay?: number;
  variant?: TypographyProps['variant'];
  color?: TypographyProps['color'];
  maxLines?: number;
  wordBreak?: boolean;
  bold?: boolean;
  tooltipPlacement?: TooltipProps['placement'];
  className?: string;
  display?: string;
  style?: CSSProperties;
  ['data-testid']?: string;
}) => {
  const [isTextClamped, setIsTextClamped] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);
  const content = (
    <StyledText
      ref={ref}
      variant={variant}
      color={color}
      $maxLines={maxLines}
      $wordBreak={wordBreak}
      $bold={bold}
      className={className}
      $display={display}
      style={style}
      data-testid={dataTestId}>
      {children}
    </StyledText>
  );

  // Trigger recalculate of text clamped since ref is not always exists on first render
  useEffect(() => {
    if (ref?.current) {
      const textClamped = checkTextClamped(ref.current);
      setIsTextClamped(textClamped);
    }
  }, [ref]);

  const textClamped = ref?.current && checkTextClamped(ref.current);
  return (
    <span>
      {isTextClamped || textClamped ? (
        <InformationTooltip
          placement={tooltipPlacement}
          leaveDelay={leaveDelay}
          title={<Typography variant="body1">{children}</Typography>}>
          {content}
        </InformationTooltip>
      ) : (
        content
      )}
    </span>
  );
};
