import React from 'react';
import styled from '@emotion/styled/macro';
import { BillingPeriod } from '@stigg/js-client-sdk';
import { PaywallPlan } from './types';
import { PaywallLocalization } from './paywallTextOverrides';
import { getPlanPrice } from '../utils/getPlanPrice';
import { Typography } from '../common/Typography';
import { TiersSelectContainer } from '../common/TiersSelectContainer';

const EMPTY_CHAR = '‎';

const PlanPriceContainer = styled(Typography)`
  word-break: break-word;
  width: 100%;
`;

const UnitSpan = styled(Typography)`
  white-space: nowrap;
`;

const PriceSpan = styled(Typography)`
  white-space: normal;
  min-height: 39px;
`;

type PriceBillingPeriodProps = {
  plan: PaywallPlan;
  billingPeriod: BillingPeriod;
  hasMonthlyPrice: boolean;
  hasAnnuallyPrice: boolean;
  paywallLocale: PaywallLocalization;
};

function PriceBillingPeriod({
  plan,
  billingPeriod,
  hasMonthlyPrice,
  hasAnnuallyPrice,
  paywallLocale,
}: PriceBillingPeriodProps) {
  const hasPrice = plan.pricePoints.find((pricePoint) => pricePoint.billingPeriod === billingPeriod);

  let content = EMPTY_CHAR;
  if (hasPrice && hasMonthlyPrice && hasAnnuallyPrice) {
    content = paywallLocale.price.billingPeriod?.(billingPeriod) || `, billed ${billingPeriod.toLowerCase()}`;
  }

  return (
    <Typography span className="stigg-price-billing-period-text" variant="h6" color="secondary">
      {content}
    </Typography>
  );
}

export const PlanPrice = ({
  showStartingAt,
  withUnitPriceRow,
  withStartingAtRow,
  withTiersRow,
  plan,
  billingPeriod,
  paywallLocale,
  locale,
  hasMonthlyPrice,
  hasAnnuallyPrice,
  perUnitQuantityByFeature,
  setPerUnitQuantityByFeature,
}: {
  showStartingAt: boolean;
  withUnitPriceRow: boolean;
  withStartingAtRow: boolean;
  withTiersRow: boolean;
  perUnitQuantityByFeature: Record<string, number>;
  setPerUnitQuantityByFeature: React.Dispatch<React.SetStateAction<Record<string, number>>>;
  plan: PaywallPlan;
  billingPeriod: BillingPeriod;
  paywallLocale: PaywallLocalization;
  hasMonthlyPrice: boolean;
  hasAnnuallyPrice: boolean;
  locale: string;
}) => {
  const { price, unit, tiers, tierUnits } = getPlanPrice(
    plan,
    billingPeriod,
    paywallLocale,
    locale,
    hasMonthlyPrice,
    perUnitQuantityByFeature,
  );

  // We currently only support prices with one tier - so we select the first one
  const tieredPrice = plan.pricePoints.find((planPrice) => {
    return planPrice.billingPeriod === billingPeriod && planPrice.isTieredPrice;
  });
  const featureId = tieredPrice ? tieredPrice.feature!.featureId : undefined;
  const perUnitQuantity = perUnitQuantityByFeature[featureId || ''] ?? 1;

  const handlePerUnitQuantityChange = (quantity: number) => {
    if (!featureId) {
      return;
    }

    setPerUnitQuantityByFeature((prevState) => ({
      ...prevState,
      [featureId]: quantity,
    }));
  };

  return (
    <PlanPriceContainer as="div" className="stigg-price-text">
      <>
        {withStartingAtRow && (
          <Typography
            style={{ minHeight: '20px' }}
            className="stigg-starting-at-text"
            variant="body1"
            color="secondary">
            {showStartingAt ? paywallLocale.price.startingAtCaption : EMPTY_CHAR}
          </Typography>
        )}

        <PriceSpan className="stigg-price-point" variant="h1">
          {price}
        </PriceSpan>

        {withUnitPriceRow && (
          <Typography as="div" className="stigg-price-unit-and-billing-period-text" style={{ minHeight: '48px' }}>
            <UnitSpan className="stigg-price-unit-text" span variant="h6" color="secondary">
              {unit}
            </UnitSpan>

            <PriceBillingPeriod
              plan={plan}
              billingPeriod={billingPeriod}
              hasAnnuallyPrice={hasAnnuallyPrice}
              hasMonthlyPrice={hasMonthlyPrice}
              paywallLocale={paywallLocale}
            />
          </Typography>
        )}

        {withTiersRow ? (
          <TiersSelectContainer
            componentId={`${plan.id}_${featureId}_tier`}
            tiers={tiers}
            tierUnits={tierUnits}
            tiersMode={tieredPrice?.tiersMode}
            handleChange={handlePerUnitQuantityChange}
            value={perUnitQuantity}
          />
        ) : null}
      </>
    </PlanPriceContainer>
  );
};
