import {
  BillingPeriod,
  Customer,
  Plan,
  Subscription,
  CustomerPortalSubscriptionScheduledUpdateDataFragment,
  PaywallCurrency,
  PaywallCalculatedPricePoint,
  BillableFeature,
} from '@stigg/js-client-sdk';
import { CustomizedTheme } from '../../theme/Theme';

export type PaywallData = {
  plans: PaywallPlan[] | null;
  customer: Customer | null;
  currentSubscription: Subscription | null;
  isCustomerOnTrial: boolean;
  isLoading: boolean;
  selectedBillingPeriod: BillingPeriod;
  setSelectedBillingPeriod: (billingPeriod: BillingPeriod) => void;
  availableBillingPeriods: BillingPeriod[];
  locale: string;
  configuration?: CustomizedTheme;
};

export enum SubscribeIntentionType {
  START_TRIAL = 'START_TRIAL',
  UPGRADE_TRIAL_TO_PAID = 'UPGRADE_TRIAL_TO_PAID',
  REQUEST_CUSTOM_PLAN_ACCESS = 'REQUEST_CUSTOM_PLAN_ACCESS',
  CHANGE_BILLING_PERIOD = 'CHANGE_BILLING_PERIOD',
  CHANGE_UNIT_QUANTITY = 'CHANGE_UNIT_QUANTITY',
  UPGRADE_PLAN = 'UPGRADE_PLAN',
  DOWNGRADE_PLAN = 'DOWNGRADE_PLAN',
  CANCEL_SCHEDULED_UPDATES = 'CANCEL_SCHEDULED_UPDATES',
}

export type PaywallPlan = Plan & {
  isLowerThanCurrentPlan?: boolean;
  isTriable: boolean;
  isCurrentCustomerPlan: boolean;
  currentCustomerPlanBillingPeriod?: BillingPeriod;
  trialDaysLeft?: number;
  isNextPlan?: (billingPeriod: BillingPeriod) => boolean;
  scheduledUpdate?: CustomerPortalSubscriptionScheduledUpdateDataFragment;
  paywallCurrency?: PaywallCurrency;
  paywallCalculatedPricePoints?: PaywallCalculatedPricePoint[];
};

export type OnPlanSelectedCallbackFn = ({
  plan,
  customer,
  subscription,
  intentionType,
  selectedBillingPeriod,
  billableFeatures,
}: {
  plan: Plan;
  customer: Customer | null;
  subscription: Subscription | null;
  intentionType: SubscribeIntentionType;
  selectedBillingPeriod: BillingPeriod;
  billableFeatures?: BillableFeature[];
}) => void | Promise<void>;

export type ShouldHidePlanFn = ({ plan }: { plan: PaywallPlan }) => boolean | Promise<boolean>;

export type SelectDefaultTierIndexFn = ({ plan }: { plan: PaywallPlan }) => number;
