import styled from '@emotion/styled/macro';

export const SectionHeader = styled.div<{ $disableMargin?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ $disableMargin }) =>
    !$disableMargin &&
    `
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  `};
`;
