import InputAdornment from '@mui/material/InputAdornment';
import React from 'react';
import { Typography } from './Typography';
import { InputField } from '../checkout/components';
import { TiersSelectContainerProps } from './TiersSelectContainer';
import { ON_WHEEL_BLUR } from '../utils/onWheelBlur';

export function VolumePerUnitInput({
  width,
  tierUnits,
  value,
  handleChange,
}: Pick<TiersSelectContainerProps, 'width' | 'tierUnits' | 'value'> & {
  handleChange: (quantity: number) => void;
}) {
  return (
    <InputField
      type="number"
      onWheel={ON_WHEEL_BLUR}
      fullWidth
      sx={{ minHeight: '46px', width }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Typography variant="body1" color="primary">
              {tierUnits}
            </Typography>
          </InputAdornment>
        ),
      }}
      value={value}
      onChange={(event) => {
        const quantity = event?.target?.value ? Number(event?.target?.value) : null;
        if (quantity) {
          handleChange(quantity);
        }
      }}
    />
  );
}
