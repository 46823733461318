import { Addon } from '@stigg/js-client-sdk';
import { useCheckoutContext } from '../CheckoutProvider';

export enum CheckoutStepKey {
  PLAN = 'PLAN',
  ADDONS = 'ADDONS',
  PAYMENT = 'PAYMENT',
}

export type CheckoutStep = {
  key: CheckoutStepKey;
  label: string;
};

const CHECKOUT_STEPS: CheckoutStep[] = [
  { key: CheckoutStepKey.PLAN, label: 'Plan' },
  { key: CheckoutStepKey.ADDONS, label: 'Add-ons' },
  { key: CheckoutStepKey.PAYMENT, label: 'Payment details' },
];

export type ProgressBarState = {
  activeStep: number;
  completedSteps: number[];
  steps: CheckoutStep[];
  isDisabled: boolean;
};

const INITIAL_STATE: ProgressBarState = {
  activeStep: 0,
  completedSteps: [],
  steps: CHECKOUT_STEPS,
  isDisabled: false,
};

export function getProgressBarInitialState({ availableAddons }: { availableAddons?: Addon[] }) {
  if (availableAddons?.length === 0) {
    return { ...INITIAL_STATE, steps: CHECKOUT_STEPS.filter((step) => step.key !== CheckoutStepKey.ADDONS) };
  }

  return INITIAL_STATE;
}

function useProgressBarState() {
  const [{ progressBar }] = useCheckoutContext();
  return progressBar;
}

function useSetActiveStep() {
  const [, setState] = useCheckoutContext();
  return (stepNumber: number) =>
    setState(({ progressBar }) => {
      progressBar.activeStep = stepNumber;
    });
}
function useMarkStepAsCompleted() {
  const [, setState] = useCheckoutContext();
  return (stepNumber: number) =>
    setState(({ progressBar }) => {
      progressBar.completedSteps.push(stepNumber);
    });
}

function isCheckoutComplete(progressBar: ProgressBarState) {
  return progressBar.completedSteps.length >= progressBar.steps.length - 1;
}

function useGoNext() {
  const [, setState] = useCheckoutContext();
  return () =>
    setState(({ progressBar }) => {
      if (progressBar.isDisabled) {
        return;
      }

      if (!progressBar.completedSteps.includes(progressBar.activeStep)) {
        progressBar.completedSteps.push(progressBar.activeStep);
      }

      if (progressBar.activeStep < progressBar.steps.length - 1) {
        progressBar.activeStep += 1;
      }
    });
}

function useSetIsDisabled() {
  const [, setState] = useCheckoutContext();
  return (isDisabled?: boolean) =>
    setState(({ progressBar }) => {
      progressBar.isDisabled = !!isDisabled;
    });
}

export function useProgressBarModel() {
  const progressBarState = useProgressBarState();
  const currentStep = progressBarState.steps[progressBarState.activeStep];

  return {
    currentStep,
    progressBarState,
    isLastStep: progressBarState.activeStep === progressBarState.steps.length - 1,
    isCheckoutComplete: isCheckoutComplete(progressBarState),
    setActiveStep: useSetActiveStep(),
    markStepAsCompleted: useMarkStepAsCompleted(),
    goNext: useGoNext(),
    setIsDisabled: useSetIsDisabled(),
  };
}
