import { ApplySubscription, PreviewSubscription } from '@stigg/js-client-sdk';
import { useCheckoutContext } from '../CheckoutProvider';
import { useCheckoutModel } from './useCheckoutModel';
import { useSubscriptionModel } from './useSubscriptionModel';

export function useSubscriptionState(): ApplySubscription | PreviewSubscription | undefined {
  const subscription = useSubscriptionModel();
  const [{ resourceId }] = useCheckoutContext();
  const { checkoutState } = useCheckoutModel();
  const { plan } = checkoutState || {};
  const addons = subscription.addons.map(({ addon, quantity }) => ({ addonId: addon.id, quantity }));

  if (!plan?.id) {
    return undefined;
  }

  return {
    resourceId,
    planId: plan.id,
    billingPeriod: subscription.billingPeriod,
    billableFeatures: subscription.billableFeatures,
    addons,
    promotionCode: subscription.promotionCode,
    billingCountryCode: subscription.billingCountryCode,
  };
}
