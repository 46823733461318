import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import { Addon, BillingPeriod, Currency, Plan, Subscription, SubscriptionAddon } from '@stigg/js-client-sdk';
import { useCheckoutContext } from '../CheckoutProvider';
import {
  filterAddons,
  filterSubscriptionAddons,
  sortAddons,
  sortSubscriptionAddons,
} from '../steps/addons/addon.utils';

export type AddonsStepState = {
  addons: SubscriptionAddon[];
  initialAddons?: SubscriptionAddon[];
  availableAddons?: Addon[];
};

type GetAddonsStepInitialStateProps = {
  plan?: Plan;
  billingPeriod: BillingPeriod;
  activeSubscription?: Subscription | null;
  billingCountryCode?: string;
};

export function getAddonsStepInitialState({
  activeSubscription,
  plan,
  billingPeriod,
  billingCountryCode,
}: GetAddonsStepInitialStateProps): AddonsStepState {
  let addons: SubscriptionAddon[] = [];
  const currency = plan?.pricePoints?.[0]?.currency || Currency.Usd;
  const availableAddons = sortAddons(
    filterAddons({
      addons: plan?.compatibleAddons,
      billingPeriod,
      billingCountryCode,
      currency,
    }),
  );

  const activeSubscriptionAddons = filterSubscriptionAddons({
    addons: activeSubscription?.addons,
    billingPeriod,
    billingCountryCode,
    currency,
  });

  if (activeSubscriptionAddons?.length && availableAddons?.length) {
    addons = sortSubscriptionAddons(
      activeSubscriptionAddons.filter((addon) => availableAddons.some((planAddon) => planAddon.id === addon.addon.id)),
    );
  }

  return { addons, initialAddons: cloneDeep(addons), availableAddons };
}

function useSetAddon() {
  const [, setState] = useCheckoutContext();

  return (addon: Addon, quantity: number) =>
    setState((draft) => {
      const addonToUpdate = draft.addonsStep.addons.find((currentAddon) => currentAddon.addon.id === addon.id);

      if (addonToUpdate) {
        addonToUpdate.quantity = quantity;
      } else {
        draft.addonsStep.addons.push({ addon, quantity });
        draft.addonsStep.addons = sortSubscriptionAddons(draft.addonsStep.addons);
      }
    });
}

function useRemoveAddon() {
  const [, setState] = useCheckoutContext();

  return (addonId: string) =>
    setState((draft) => {
      remove(draft.addonsStep.addons, (addon) => addon.addon.id === addonId);
    });
}

function useAddonsState() {
  const [{ addonsStep }] = useCheckoutContext();
  return addonsStep;
}

export function useAddonsStepModel() {
  const state = useAddonsState();

  return {
    ...state,
    setAddon: useSetAddon(),
    removeAddon: useRemoveAddon(),
  };
}
