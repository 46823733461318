import React, { useContext } from 'react';
import { CustomerPortal } from '@stigg/js-client-sdk';
import { useCustomerPortal } from './hooks/useCustomerPortal';
import { DeepPartial } from '../../types';
import { CustomerPortalLocalization, getResolvedCustomerPortalLocalize } from './customerPortalTextOverrides';
import { CustomerPortalTheme, getResolvedCustomerPortalTheme } from './customerPortalTheme';
import { SdkThemeProvider, useStiggTheme } from '../../theme/Theme';
import { useStiggContext } from '../..';
import { mapCustomerPortalConfiguration } from '../common/mapExternalTheme';

export interface CustomerPortalContextValue {
  customerPortal?: CustomerPortal | null;
  textOverrides: CustomerPortalLocalization;
  theme: CustomerPortalTheme;
  isLoading: boolean;
  resourceId?: string;
}

export const CustomerPortalContext = React.createContext<CustomerPortalContextValue | null>(null);
CustomerPortalContext.displayName = 'CustomerPortalContext';

export const useCustomerPortalContext = (): CustomerPortalContextValue => {
  const ctx = useContext(CustomerPortalContext);
  if (!ctx) {
    throw new Error(
      'Could not find Customer portal context; You need to wrap your customer portal components in an <CustomerPortalProvider> component.',
    );
  }
  return ctx;
};

export function useCheckContextExists<T>(contextType: React.Context<T>) {
  const ctx = useContext(contextType);
  return !!ctx;
}

export function CustomerPortalProvider({
  children,
  textOverrides,
  theme,
  resourceId,
  productId,
}: {
  children: React.ReactNode;
  textOverrides?: DeepPartial<CustomerPortalLocalization>;
  theme?: DeepPartial<CustomerPortalTheme>;
  resourceId?: string;
  productId?: string;
}) {
  const { customerPortalUpdatedAt } = useStiggContext();
  const { customerPortal, isLoading } = useCustomerPortal({ resourceId, productId });
  const configuration = customerPortal?.configuration
    ? mapCustomerPortalConfiguration(customerPortal.configuration)
    : undefined;
  const globalTheme = useStiggTheme(configuration);
  const customerPortalTheme = getResolvedCustomerPortalTheme(globalTheme, theme, customerPortal?.configuration);
  const resolvedTextOverrides = getResolvedCustomerPortalLocalize(textOverrides);

  return (
    <SdkThemeProvider componentTheme={configuration}>
      <CustomerPortalContext.Provider
        key={customerPortalUpdatedAt?.toString()}
        value={{
          customerPortal,
          isLoading,
          textOverrides: resolvedTextOverrides,
          theme: customerPortalTheme,
          resourceId,
        }}>
        {children}
      </CustomerPortalContext.Provider>
    </SdkThemeProvider>
  );
}
