import { BillingPeriod, PricingType } from '@stigg/js-client-sdk';
import { ShouldHidePlanFn, PaywallPlan } from '../types';
import { hasPricePoints } from './hasPricePoints';

export function getPlansToDisplay(
  plans: PaywallPlan[],
  selectedBillingPeriod: BillingPeriod,
  shouldHidePlan?: ShouldHidePlanFn,
): PaywallPlan[] {
  return plans
    .filter((plan) => plan.pricingType !== PricingType.Paid || hasPricePoints(plan, selectedBillingPeriod))
    .filter((plan) => !shouldHidePlan || !shouldHidePlan({ plan }));
}
