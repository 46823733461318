import React, { useRef } from 'react';
import { SubscriptionsOverview } from './subscriptionOverview/SubscriptionsOverview';
import { CustomerPortalHeader } from './CustomerPortalHeader';
import { PaymentDetailsSection } from './billing/PaymentDetailsSection';
import { CustomerPortalPaywall } from './paywall/CustomerPortalPaywall';
import { useCustomerPortalContext } from './CustomerPortalProvider';
import { CustomerPortalLayout, CustomerPortalSections } from './CustomerPortal.style';
import { CustomerPortalProps } from './CustomerPortal';
import { InvoicesSection, useStiggContext } from '../..';
import { CustomerUsageData } from './usage/CustomerUsageData';
import { CustomerPortalIntentionType } from './types';

export type OnManageClick = ({ intentionType }: { intentionType: CustomerPortalIntentionType }) => void;

export function CustomerPortalContainer({
  onManageSubscription,
  onBuyMore,
  onCancelScheduledUpdates,
  onContactSupport,
  paywallComponent,
  hiddenSections,
  filterEntitlements,
}: CustomerPortalProps) {
  const { stigg } = useStiggContext();
  const { customerPortal, textOverrides, theme, isLoading } = useCustomerPortalContext();
  const customerPortalSectionRef = useRef<HTMLDivElement>(null);

  const onManageClick: OnManageClick = ({ intentionType }: { intentionType: CustomerPortalIntentionType }) => {
    if (onManageSubscription) {
      void onManageSubscription({
        intentionType,
        customerSubscriptions: customerPortal?.subscriptions ?? [],
      });
    } else {
      customerPortalSectionRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const shouldShowUsage = !hiddenSections?.some((section) => section === 'usage');
  const shouldShowPaymentDetails = !hiddenSections?.some(
    (section) => section === 'paymentDetails' || section === 'billingInformation',
  );
  const shouldShowInvoices = !hiddenSections?.some(
    (section) => section === 'invoices' || section === 'billingInformation',
  );

  return (
    <CustomerPortalLayout className="stigg-customer-portal-layout">
      <CustomerPortalHeader showWatermark={stigg.isWidgetWatermarkEnabled && !!customerPortal} />
      <CustomerPortalSections className="stigg-customer-portal-sections">
        <SubscriptionsOverview
          onCancelScheduledUpdates={onCancelScheduledUpdates}
          onBuyMore={onBuyMore}
          onManageSubscription={onManageClick}
          onContactSupport={onContactSupport}
          hiddenSections={hiddenSections}
          cancelScheduledUpdatesButtonTitle={textOverrides.cancelScheduledUpdatesButtonTitle}
        />
        {shouldShowUsage && (
          <CustomerUsageData
            onManageSubscription={onManageClick}
            onBuyMore={onBuyMore}
            filterEntitlements={filterEntitlements}
          />
        )}
        <CustomerPortalPaywall
          ref={customerPortalSectionRef}
          paywallComponent={paywallComponent}
          theme={theme}
          title={textOverrides.paywallSectionTitle}
          isLoading={!customerPortal || isLoading}
        />
        {shouldShowPaymentDetails && <PaymentDetailsSection />}
        {shouldShowInvoices && <InvoicesSection />}
      </CustomerPortalSections>
    </CustomerPortalLayout>
  );
}
