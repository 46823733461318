import { Currency } from '@stigg/js-client-sdk';
import getSymbolFromCurrency from 'currency-symbol-map';

export const currencyPriceFormatter = ({
  amount,
  currency = Currency.Usd,
  locale,
  maximumFractionDigits = 5,
  minimumFractionDigits = 0,
}: {
  amount: number;
  currency?: Currency | string;
  locale?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
}) => {
  const currencyString = currency.toString();
  const currencySymbol = getSymbolFromCurrency(currencyString);
  let formattedPrice = new Intl.NumberFormat(locale, {
    maximumFractionDigits,
    minimumFractionDigits,
    style: 'currency',
    currency: currencyString,
    ...(currencySymbol ? { currencyDisplay: 'code' } : {}),
  }).format(amount || 0);

  if (currencySymbol) {
    formattedPrice = formattedPrice.replace(currencyString, currencySymbol);
  }

  // remove space between currency symbol and price
  formattedPrice = formattedPrice.replace(/\s/g, '');

  return formattedPrice;
};
