import React from 'react';
import { PoweredByStigg } from '../../common/PoweredByStigg';
import { SummaryCard, SummaryContainer } from './CheckoutSummary';
import { FlexedSkeleton, Skeleton, SkeletonsContainer } from '../components/Skeletons.style';

export const CheckoutSummarySkeleton = () => {
  return (
    <SummaryContainer>
      <SummaryCard>
        <SkeletonsContainer $flexDirection="column" $gap={24}>
          <Skeleton width={120} height={20} />

          <Skeleton width={120} height={20} />

          <Skeleton width={120} height={20} />

          <Skeleton width={120} height={20} />
        </SkeletonsContainer>

        <SkeletonsContainer $flexDirection="column" $gap={24} marginTop={5}>
          <Skeleton height={20} />

          <Skeleton height={20} />

          <Skeleton height={20} />
        </SkeletonsContainer>

        <FlexedSkeleton flex={1} marginTop={5}>
          <Skeleton height={34} />
        </FlexedSkeleton>
      </SummaryCard>
      <PoweredByStigg
        source="checkout"
        showWatermark
        style={{ marginTop: 8, display: 'flex', justifyContent: 'center' }}
      />
    </SummaryContainer>
  );
};
