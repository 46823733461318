import { FontWeight, TypographyConfiguration } from '@stigg/js-client-sdk';

export const defaultCheckoutTypography: TypographyConfiguration = {
  h1: {
    fontSize: 24,
    fontWeight: FontWeight.Bold,
  },
  h2: {
    fontSize: 16,
    fontWeight: FontWeight.Normal,
  },
  h3: {
    fontSize: 16,
    fontWeight: FontWeight.Normal,
  },
  body: {
    fontSize: 14,
    fontWeight: FontWeight.Normal,
  },
};
