import { ApplySubscription, ApplySubscriptionResults } from '@stigg/js-client-sdk';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useStiggContext } from '../../../../..';
import { useCheckoutModel } from '../../../hooks';
import { usePaymentStepModel } from '../../../hooks/usePaymentStepModel';
import { useSubscriptionState } from '../../../hooks/useSubscriptionState';
import { CheckoutContainerProps, CheckoutResult } from '../../../CheckoutContainer';
import { handleNewPaymentMethod, handleStripeNextAction } from './stripe.utils';
import { ANIMATION_DURATION } from '../../../summary/CheckoutSuccess';

const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export type HandleSubmitResult = { results?: ApplySubscriptionResults; success: boolean; errorMessage?: string };

export type UseSubmitProps = {
  onSuccess?: () => void;
  isMocked?: boolean;
} & Pick<CheckoutContainerProps, 'onCheckout' | 'onCheckoutCompleted' | 'disableSuccessAnimation'>;

export function useSubmit({
  isMocked = false,
  onCheckout,
  onCheckoutCompleted,
  onSuccess,
  disableSuccessAnimation,
}: UseSubmitProps) {
  const { stigg } = useStiggContext();
  const { useNewPaymentMethod } = usePaymentStepModel();
  const subscriptionState = useSubscriptionState();
  const { checkoutState, widgetState, setWidgetReadOnly } = useCheckoutModel();
  const { setupSecret: setupIntentClientSecret, customer } = checkoutState || {};
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e: any): Promise<HandleSubmitResult> => {
    e.preventDefault();

    if (!subscriptionState) {
      return { success: false, errorMessage: 'Unexpected error, please contact support.' };
    }

    let success = true;
    let errorMessage: string | undefined;
    let usedStiggCheckoutAction = false;
    let paymentMethodId: string | undefined;

    setWidgetReadOnly(true);

    if (!isMocked && useNewPaymentMethod) {
      const paymentMethodResults = await handleNewPaymentMethod({ elements, stripe, setupIntentClientSecret });
      if (!paymentMethodResults.success) {
        errorMessage = paymentMethodResults.errorMessage;
        success = false;
      } else {
        paymentMethodId = paymentMethodResults.paymentMethodId;
      }
    }

    const checkoutParams: ApplySubscription = { ...subscriptionState, paymentMethodId };

    const checkoutAction = async (params: ApplySubscription): Promise<CheckoutResult> => {
      usedStiggCheckoutAction = true;

      try {
        const applySubscriptionResults = await stigg.applySubscription(params);

        const nextActionResults = await handleStripeNextAction({ applySubscriptionResults, stripe });

        return {
          success: !nextActionResults.errorMessage,
          errorMessage: nextActionResults.errorMessage,
          results: applySubscriptionResults,
        };
      } catch (e) {
        console.error(e);
        errorMessage = (e as any)?.message;
        return { success: false, errorMessage };
      }
    };

    let checkoutResults: ApplySubscriptionResults | undefined;

    if (success) {
      if (onCheckout) {
        const externalCheckoutResults = await onCheckout({
          customerId: customer!.id,
          checkoutParams,
          checkoutAction,
        });
        if (externalCheckoutResults.errorMessage) {
          errorMessage = externalCheckoutResults.errorMessage;
        }

        if (!usedStiggCheckoutAction && externalCheckoutResults.results && externalCheckoutResults.success) {
          const nextActionResults = await handleStripeNextAction({
            applySubscriptionResults: externalCheckoutResults.results,
            stripe,
          });

          if (nextActionResults.errorMessage) {
            errorMessage = nextActionResults.errorMessage;
            success = false;
          }
        }

        success = success && externalCheckoutResults.success && !errorMessage;
        checkoutResults = externalCheckoutResults.results;
      } else {
        const checkoutActionResults = await checkoutAction(checkoutParams);
        if (!checkoutActionResults.success && checkoutActionResults.errorMessage) {
          errorMessage = checkoutActionResults.errorMessage;
        }
        success = checkoutActionResults.success && !errorMessage;
        checkoutResults = checkoutActionResults.results;
      }
    }

    setWidgetReadOnly(false);

    if (success && onSuccess) {
      onSuccess();

      if (!disableSuccessAnimation) {
        await delay(ANIMATION_DURATION); // Wait for animation to finish
      }
    }

    await onCheckoutCompleted({ success, error: errorMessage });

    return { results: checkoutResults, success: !errorMessage, errorMessage };
  };

  return { handleSubmit, isLoading: !!widgetState?.readOnly };
}
