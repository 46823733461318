import { BillingPeriod, PaywallCalculatedPricePoint, Price } from '@stigg/js-client-sdk';
import isNil from 'lodash/isNil';
import { PaywallPlan } from '../paywall';
import { calculateTierPrice } from './priceTierUtils';

export function calculateDiscountRate(monthlyPrice?: number | null, annuallyPrice?: number | null) {
  if (!isNil(monthlyPrice) && !isNil(annuallyPrice)) {
    const annuallyPerMonthPrice = annuallyPrice / 12;
    return Math.round(((monthlyPrice - annuallyPerMonthPrice) / monthlyPrice) * 100);
  }

  return null;
}

function getPlanBillingPeriodAmount(plan: PaywallPlan, billingPeriod: BillingPeriod) {
  let pricePoint: PaywallCalculatedPricePoint | Price | undefined;

  pricePoint = plan.paywallCalculatedPricePoints?.find((price) => price.billingPeriod === billingPeriod);

  if (!pricePoint) {
    pricePoint = plan.pricePoints.find((price) => price.billingPeriod === billingPeriod);
  }

  if (!pricePoint?.amount) {
    const tieredPrice = plan.pricePoints.find((price) => {
      return price.isTieredPrice && price.billingPeriod === billingPeriod;
    });

    if (tieredPrice) {
      return calculateTierPrice(tieredPrice, 1);
    }
  }

  return pricePoint?.amount;
}

export function calculatePaywallDiscountRate(plans: PaywallPlan[]) {
  return plans.reduce((maxDiscount, plan) => {
    const monthlyAmount = getPlanBillingPeriodAmount(plan, BillingPeriod.Monthly);
    const annuallyAmount = getPlanBillingPeriodAmount(plan, BillingPeriod.Annually);

    const discountRate = calculateDiscountRate(monthlyAmount, annuallyAmount);
    if (discountRate) {
      maxDiscount = Math.max(maxDiscount, discountRate);
    }

    return maxDiscount;
  }, 0);
}
