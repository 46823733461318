import merge from 'lodash/merge';
import { DeepPartial } from '../../types';

export type CustomerPortalLocalization = {
  manageSubscription: string;
  usageTabTitle: string;
  addonsTabTitle: string;
  promotionsTabTitle: string;
  promotionsSubtitle: string;
  contactSupportTitle: string;
  contactSupportLink: string;
  editBilling: string;
  invoicesTitle: string;
  viewInvoiceHistory: string;
  editPaymentDetails: string;
  paywallSectionTitle: string;
  cancelScheduledUpdatesButtonTitle: string;
};

export function getResolvedCustomerPortalLocalize(
  localizeOverride?: DeepPartial<CustomerPortalLocalization>,
): CustomerPortalLocalization {
  const customerPortalDefaultLocalization: CustomerPortalLocalization = {
    manageSubscription: 'Manage',
    usageTabTitle: 'Usage',
    addonsTabTitle: 'Add-ons',
    promotionsTabTitle: 'Promotions',
    promotionsSubtitle: 'You were granted access to additional functionality at no additional cost.',
    contactSupportTitle: 'Have questions about your subscription?',
    contactSupportLink: 'Contact support',
    editBilling: 'Edit billing details',
    invoicesTitle: 'Invoices',
    viewInvoiceHistory: 'View invoice history',
    editPaymentDetails: 'Edit',
    paywallSectionTitle: 'Plans',
    cancelScheduledUpdatesButtonTitle: 'Cancel',
  };
  return merge(customerPortalDefaultLocalization, localizeOverride);
}
