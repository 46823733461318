import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';

export const PaymentMethodContainer = styled(Grid, { shouldForwardProp: (prop) => !prop.startsWith('$') })<{
  $disabled?: boolean;
}>`
  padding: 8px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.stigg.palette.outlinedBorder};
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ $disabled }) => ($disabled ? 0.6 : 1)};
`;

export const NewPaymentMethodContainer = styled(PaymentMethodContainer)`
  flex-direction: column;
  align-items: unset;
`;

export const PaymentMethodLayoutContainer = styled(Grid)`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 12px;
`;

export const PaymentMethodTextContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
`;
