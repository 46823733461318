import React from 'react';
import moment from 'moment';
import isNil from 'lodash/isNil';
import {
  CustomerPortalSubscription,
  CustomerPortalSubscriptionScheduledUpdateDataFragment,
  SubscriptionScheduleType,
} from '@stigg/js-client-sdk';
import { CustomerPortalSubscriptionPriceFragment } from '@stigg/api-client-js/src/generated/sdk';
import { compact, map } from 'lodash';
import { BillingPeriodChangeVariables, UnitAmountChangeVariables } from '../../types';

const formatDate = (date: string) => moment.utc(date).format('MMMM Do, yyyy');

type GetSubscriptionScheduleUpdateTextsProps = {
  scheduledUpdate: CustomerPortalSubscriptionScheduledUpdateDataFragment | undefined;
  subscriptionPrices?: CustomerPortalSubscriptionPriceFragment[];
  billingPeriodRange?: CustomerPortalSubscription['billingPeriodRange'];
  options?: { withDate?: boolean; postfix?: string };
};

export function getSubscriptionScheduleUpdateTexts({
  scheduledUpdate,
  subscriptionPrices,
  billingPeriodRange,
  options = {},
}: GetSubscriptionScheduleUpdateTextsProps) {
  if (!scheduledUpdate) {
    return {};
  }
  const { withDate, postfix = '' } = options;

  switch (scheduledUpdate.subscriptionScheduleType) {
    case SubscriptionScheduleType.Downgrade: {
      let shouldShowDate = withDate;
      if (isNil(shouldShowDate) && billingPeriodRange?.end) {
        shouldShowDate = !moment(scheduledUpdate.scheduledExecutionTime).isSame(billingPeriodRange?.end, 'day');
      }

      const line1 = (
        <>
          Your subscription will be downgraded to the <b>{scheduledUpdate.targetPackage?.displayName} plan</b>{' '}
          {shouldShowDate ? <>on {formatDate(scheduledUpdate.scheduledExecutionTime)}</> : postfix}.
        </>
      );
      const line2 = `Until then you still have access to your current plan.`;

      return { line1, line2 };
    }

    case SubscriptionScheduleType.BillingPeriod: {
      const variables = scheduledUpdate.scheduleVariables as BillingPeriodChangeVariables;
      const line1 = (
        <>
          Your billing cycle will change to <b>{variables.billingPeriod?.toLocaleLowerCase()}</b>{' '}
          {withDate ? <>on {formatDate(scheduledUpdate.scheduledExecutionTime)}</> : postfix}.
        </>
      );

      return { line1 };
    }

    case SubscriptionScheduleType.UnitAmount: {
      const variables = scheduledUpdate.scheduleVariables as UnitAmountChangeVariables;
      const feature = compact(map(subscriptionPrices, 'feature')).find(({ id }) => id === variables.featureId);
      const { featureUnits, featureUnitsPlural } = feature || {};
      const unitsText = variables.newUnitAmount === 1 ? featureUnits : featureUnitsPlural;

      const line1 = (
        <>
          Your subscription will be updated to <b>{variables.newUnitAmount}</b> {unitsText}{' '}
          {withDate ? <>on {formatDate(scheduledUpdate.scheduledExecutionTime)}</> : postfix}.
        </>
      );

      const line2 = `Until then you still have access to your current ${featureUnits} count.`;

      return { line1, line2 };
    }

    default: {
      return {};
    }
  }
}
