import React from 'react';
import styled from '@emotion/styled/macro';
import { BillingPeriod, WidgetType } from '@stigg/js-client-sdk';
import { EntitlementRow } from './EntitlementRow';
import { PaywallPlan } from './types';
import { PaywallLocalization } from './paywallTextOverrides';
import { Typography } from '../common/Typography';

const PlanEntitlementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

function getTitle(plan: PaywallPlan, paywallLocale: PaywallLocalization, hasFeatures: boolean) {
  if (paywallLocale.entitlementsTitle) {
    return paywallLocale.entitlementsTitle(plan);
  }
  let title = hasFeatures ? `${plan.displayName} includes` : '';
  if (plan.basePlan) {
    title = `Everything in ${plan.basePlan.displayName}${hasFeatures ? ', plus:' : ''}`;
  }

  return title;
}

export function PlanEntitlements({
  plan,
  billingPeriod,
  paywallLocale,
}: {
  plan: PaywallPlan;
  billingPeriod: BillingPeriod;
  paywallLocale: PaywallLocalization;
}) {
  const prices = plan.pricePoints?.filter((price) => price.billingPeriod === billingPeriod) || [];
  const unitBasedEntitlements = prices
    .filter((price) => (price?.minUnitQuantity && price?.minUnitQuantity > 1) || price?.maxUnitQuantity)
    .map((price) => {
      return (
        <EntitlementRow
          key={`priceUnitEntitlement-${price?.feature?.displayName}`}
          feature={price?.feature}
          minUnitQuantity={price?.minUnitQuantity}
          maxUnitQuantity={price?.maxUnitQuantity}
        />
      );
    });

  const visibleEntitlements = plan.entitlements.filter(
    (entitlement) => !(entitlement.hiddenFromWidgets || []).includes(WidgetType.Paywall),
  );

  const hasFeatures = unitBasedEntitlements.length > 0 || visibleEntitlements.length > 0;

  return (
    <PlanEntitlementsContainer className="stigg-plan-entitlements-container">
      <Typography className="stigg-plan-entitlements-title" color="secondary" variant="h6" bold>
        {getTitle(plan, paywallLocale, hasFeatures)}
      </Typography>
      {unitBasedEntitlements}
      {visibleEntitlements.map((entitlement) => (
        <EntitlementRow
          key={entitlement.feature?.id}
          displayNameOverride={entitlement.displayNameOverride}
          feature={entitlement.feature}
          resetPeriod={entitlement.resetPeriod}
          hasUnlimitedUsage={entitlement.hasUnlimitedUsage}
          usageLimit={entitlement.usageLimit}
          isCustom={entitlement.isCustom}
        />
      ))}
    </PlanEntitlementsContainer>
  );
}
