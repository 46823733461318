import React from 'react';
import styled from '@emotion/styled/macro';

import PlusIcon from '../../../assets/plus-icon.svg';
import { Typography } from '../../common/Typography';
import { Button } from '../components';
import { CheckoutLocalization } from '../configurations/textOverrides';

const StyledPlusIcon = styled(PlusIcon, { shouldForwardProp: (prop) => !prop.startsWith('$') })<{ $disabled: boolean }>`
  margin-right: 8px;
  path {
    stroke: ${({ theme, $disabled }) => ($disabled ? theme.stigg.palette.text.disabled : theme.stigg.palette.primary)};
  }
`;

export type AddPromotionCodeButtonProps = {
  onAddClick: () => void;
  checkoutLocalization: CheckoutLocalization;
  disabled: boolean;
};

export const AddPromotionCodeButton = ({ onAddClick, checkoutLocalization, disabled }: AddPromotionCodeButtonProps) => (
  <Button
    disabled={disabled}
    fullWidth
    className="stigg-checkout-summary-add-coupon-code-button"
    sx={{
      textTransform: 'none',
      justifyContent: 'flex-start',
      color: disabled ? 'text.disabled' : 'primary.main',
    }}
    variant="text"
    size="medium"
    onClick={onAddClick}>
    <StyledPlusIcon $disabled={disabled} className="stigg-checkout-summary-add-coupon-code-button-icon" />
    <Typography
      className="stigg-checkout-change-plan-button-text"
      color={disabled ? 'disabled' : 'primary.main'}
      style={{ lineHeight: '24px' }}
      variant="h3">
      {checkoutLocalization.summary.addCouponCodeText}
    </Typography>
  </Button>
);
