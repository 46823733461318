import styled from '@emotion/styled/macro';

export const SectionContainer = styled.div<{ $backgroundColor: string; $borderColor: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border: ${({ $borderColor }) => `1px solid ${$borderColor}`};
  padding: 64px;
`;
