import React from 'react';
import styled from '@emotion/styled/macro';
import { ExternalLink as ExternalLinkIcon } from 'react-feather';
import { Typography } from '../../common/Typography';
import { StyledButton } from './StyledButton';

const ExternalLinkText = styled(Typography)`
  text-transform: none;
`;

const LinkIcon = styled(ExternalLinkIcon)`
  color: ${({ theme }) => theme.stigg.palette.primary};
`;

export type ExternalLinkButtonProps = {
  text: string;
  url: string;
  className: string;
};

export function ExternalLinkButton({ text, url, className }: ExternalLinkButtonProps) {
  const onClick = () => {
    window.open(url, '_blank');
  };

  return (
    <StyledButton
      className={className}
      variant="outlined"
      startIcon={<LinkIcon size={20} strokeWidth={2.5} />}
      onClick={onClick}>
      <ExternalLinkText color="primary.main">{text}</ExternalLinkText>
    </StyledButton>
  );
}
