import React from 'react';
import styled from '@emotion/styled';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { useCheckoutModel, usePaymentStepModel } from '../../hooks';
import { ExistingPaymentMethod, NewPaymentMethod } from './PaymentMethods';
import { Typography } from '../../../common/Typography';
import { CheckoutContainerProps } from '../../CheckoutContainer';

const PaymentContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export function PaymentStep({
  onBillingAddressChange,
  collectPhoneNumber,
}: Pick<CheckoutContainerProps, 'onBillingAddressChange' | 'collectPhoneNumber'>) {
  const { checkoutState, checkoutLocalization, widgetState } = useCheckoutModel();
  const { customer } = checkoutState || {};
  const { errorMessage, useNewPaymentMethod, setUseNewPaymentMethod } = usePaymentStepModel();
  const { readOnly } = widgetState;

  const handleOnSelect = (openNewPaymentMethod: boolean) => {
    if (readOnly) {
      return;
    }

    setUseNewPaymentMethod(openNewPaymentMethod);
  };

  return (
    <PaymentContainer container>
      {!!errorMessage && (
        <Alert severity="error" sx={{ alignItems: 'center' }}>
          <Typography overrideColor="inherit">{errorMessage}</Typography>
        </Alert>
      )}
      <ExistingPaymentMethod
        readOnly={readOnly}
        checked={!useNewPaymentMethod}
        paymentMethodDetails={customer?.paymentMethodDetails}
        onSelect={() => handleOnSelect(false)}
      />
      <NewPaymentMethod
        readOnly={readOnly}
        checked={useNewPaymentMethod}
        checkoutLocalization={checkoutLocalization}
        onSelect={() => handleOnSelect(true)}
        onBillingAddressChange={onBillingAddressChange}
        collectPhoneNumber={collectPhoneNumber}
      />
    </PaymentContainer>
  );
}
