import React, { ReactElement } from 'react';
import Divider from '@mui/material/Divider';
import { padStart } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { useCustomerPortalContext } from '../CustomerPortalProvider';
import { SectionTitle } from '../common/SectionTitle';
import { SectionContainer } from '../common/SectionContainer';
import { ExternalLinkButton } from '../common/ExternalLinkButton';
import { InformationGrid, InformationGridContainer } from './InformationGrid';
import { SectionHeader } from '../common/SectionHeader';
import { SkeletonButton } from '../common/SkeletonButton';

export const EMPTY_CHAR = '-';

export function PaymentDetailsSection() {
  const { customerPortal, isLoading, textOverrides, theme } = useCustomerPortalContext();
  const { billingInformation } = customerPortal || {};
  const isLoadingData = !billingInformation || isLoading;

  const items: ReactElement[] = [];

  if (isLoadingData) {
    items.push(
      ...[
        <InformationGridContainer key="information-loading-data-top">
          <Skeleton width={220} height={12} />
          <Skeleton width={220} height={12} />
          <Skeleton width={220} height={12} />
        </InformationGridContainer>,
        <Divider key="information-loading-data-divider" orientation="vertical" style={{ minHeight: 93 }} />,
        <InformationGridContainer key="information-loading-data-bottom">
          <Skeleton width={220} height={12} />
          <Skeleton width={220} height={12} />
          <Skeleton width={220} height={12} />
        </InformationGridContainer>,
      ],
    );
  } else {
    items.push(
      <InformationGrid
        key="information-billing-data-billed-to"
        title="Billed to"
        rows={[
          {
            classNamePrefix: 'stigg-customer-name',
            label: 'Name',
            value: billingInformation?.name || EMPTY_CHAR,
            valueTypographyProps: billingInformation?.name ? {} : { color: 'disabled' },
          },
          {
            classNamePrefix: 'stigg-customer-email',
            label: 'Email',
            value: billingInformation?.email || EMPTY_CHAR,
            valueTypographyProps: billingInformation?.email ? {} : { color: 'disabled' },
          },
        ]}
      />,
    );

    if (
      billingInformation?.defaultPaymentMethodId &&
      billingInformation.defaultPaymentMethodLast4Digits &&
      billingInformation.defaultPaymentExpirationMonth &&
      billingInformation.defaultPaymentExpirationYear
    ) {
      items.unshift(
        <Divider key="information-billing-data-divider" orientation="vertical" style={{ minHeight: 93 }} />,
      );
      items.unshift(
        <InformationGrid
          key="information-billing-data-payment-method"
          title="Payment method"
          rows={[
            {
              classNamePrefix: 'stigg-credit-card',
              label: 'Card',
              value: `Ending in ${billingInformation.defaultPaymentMethodLast4Digits}`,
            },
            {
              classNamePrefix: 'stigg-credit-card-expiration',
              label: 'Expires',
              value: `${padStart(billingInformation.defaultPaymentExpirationMonth.toString(), 2, '0')}/${
                billingInformation.defaultPaymentExpirationYear
              }`,
            },
          ]}
        />,
      );
    }
  }

  if (
    !isLoadingData &&
    !billingInformation?.name &&
    !billingInformation?.email &&
    !billingInformation?.defaultPaymentMethodId
  ) {
    return null;
  }

  const editButton = isLoadingData ? (
    <SkeletonButton />
  ) : customerPortal?.billingPortalUrl ? (
    <ExternalLinkButton
      url={customerPortal.billingPortalUrl}
      text={textOverrides.editPaymentDetails}
      className="stigg-edit-payment-details-button"
    />
  ) : null;

  return (
    <SectionContainer
      className="stigg-payment-details-section-layout"
      $backgroundColor={theme.backgroundColor}
      $borderColor={theme.borderColor}>
      <SectionHeader className="stigg-payment-details-section-header">
        <SectionTitle
          isLoading={isLoadingData}
          className="stigg-payment-details-section-title"
          title="Payment details"
        />
        {editButton}
      </SectionHeader>

      <div style={{ display: 'flex', alignItems: 'stretch', gap: 64 }}>{items}</div>
    </SectionContainer>
  );
}
