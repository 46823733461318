import React from 'react';
import { Typography } from '../../../common/Typography';

type NoUpcomingBillingProps = {
  description: string;
};

export function NoUpcomingBilling({ description }: NoUpcomingBillingProps) {
  return (
    <>
      <Typography variant="h6" color="secondary">
        No upcoming bill
      </Typography>
      <Typography variant="body1" color="secondary">
        {description}
      </Typography>
    </>
  );
}
