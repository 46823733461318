import styled from '@emotion/styled/macro';
import React, { CSSProperties } from 'react';
import { css, useTheme } from '@emotion/react';
import { getIconColor, IconColor } from './iconColor';
import * as customIcons from './customIcons';

export type Icons = keyof typeof customIcons;

const IconWrapper = styled.div<{ $pathColor?: string; $rectColor?: string; $strokeColor?: string }>`
  ${({ $pathColor }) =>
    $pathColor &&
    css`
      path {
        fill: ${$pathColor};
      }
    `}

  ${({ $rectColor }) =>
    $rectColor &&
    css`
      svg rect {
        fill: ${$rectColor};
      }
    `}


  ${({ $strokeColor }) =>
    $strokeColor &&
    css`
      g {
        stroke: ${$strokeColor};
      }
    `}
`;

export type IconProps = {
  icon: Icons;
  className?: string;
  style?: CSSProperties;
  svgPathColor?: IconColor | string;
  svgRectColor?: IconColor | string;
  svgStrokeColor?: IconColor | string;
};

export function Icon({ icon, className, style, svgPathColor, svgRectColor, svgStrokeColor }: IconProps) {
  const IconComponent = (customIcons as any)[icon];
  const theme = useTheme();

  return (
    <IconWrapper
      className={className}
      style={style}
      $pathColor={getIconColor(svgPathColor, theme)}
      $rectColor={getIconColor(svgRectColor, theme)}
      $strokeColor={getIconColor(svgStrokeColor, theme)}>
      <IconComponent />
    </IconWrapper>
  );
}
