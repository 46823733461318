import React, { CSSProperties, forwardRef } from 'react';
import styled from '@emotion/styled/macro';
import { FontWeight as StyledFontWeight, Text, TypeProps } from 'styled-typography';
import { Theme, useTheme } from '@emotion/react';
import { FontWeight } from '../../theme/types';

type Colors =
  | 'primary'
  | 'primary.main'
  | 'primary.main.light'
  | 'secondary'
  | 'tertiary'
  | 'disabled'
  | 'white'
  | 'warning'
  | 'error';

export type TypographyProps = {
  children: React.ReactNode;
  variant?: 'h1' | 'h3' | 'h6' | 'body1' | 'caption';
  style?: CSSProperties | undefined;
  color?: Colors;
  // This is being used when passing color from the theming which
  // can be any color and not from the closed list of colors above
  overrideColor?: string;
  className?: string;
  bold?: boolean;
  span?: boolean;
  as?: 'div' | 'p' | 'span';
} & Omit<TypeProps, 'color' | 'level'>;

function getColor(theme: Theme, $color: Colors) {
  switch ($color) {
    case 'white':
      return 'white';
    case 'primary.main':
      return theme.stigg.palette.primary;
    case 'primary.main.light':
      return theme.stigg.palette.primaryLight;
    case 'warning':
      return theme.stigg.palette.warning;
    case 'error':
      return theme.stigg.palette.error;
    default:
      return theme.stigg.palette.text[$color];
  }
}

const StyledText = styled(Text)<{ $span?: boolean }>`
  margin: 0;
  ${({ $span }) => $span && `display: inline`};
`;

function getLevel(variant: TypographyProps['variant']) {
  switch (variant) {
    case 'h1':
      return 1;
    case 'h3':
      return 2;
    case 'h6':
      return 3;
    case 'body1':
      return 4;
    case 'caption':
      return 5;
    default:
      return 3;
  }
}

function mapFontWeight(fontWeight: FontWeight) {
  switch (fontWeight) {
    case 'bold':
      return StyledFontWeight.Bold;
    case 'normal':
      return StyledFontWeight.Normal;
    default:
      return StyledFontWeight.Normal;
  }
}

function getFontWeight(theme: Theme, variant: TypographyProps['variant']) {
  const { typography } = theme.stigg;
  switch (variant) {
    case 'h1':
      return mapFontWeight(typography.h1.fontWeight);
    case 'h3':
      return mapFontWeight(typography.h2.fontWeight);
    case 'h6':
      return mapFontWeight(typography.h3.fontWeight);
    case 'body1':
      return mapFontWeight(typography.body.fontWeight);
    case 'caption':
      return mapFontWeight(typography.caption.fontWeight);
    default:
      return StyledFontWeight.Normal;
  }
}

export const Typography = forwardRef((props: TypographyProps, ref) => {
  const {
    children,
    span,
    style,
    fontWeight,
    variant = 'body1',
    color = 'primary',
    overrideColor,
    bold,
    as = 'p',
    ...rest
  } = props;
  const theme = useTheme();
  const level = getLevel(variant);
  const levelClassName = `typography-level-${level}`;
  const className = props.className ? `${props.className} ${levelClassName}` : levelClassName;

  return (
    <StyledText
      as={as}
      ref={ref}
      {...rest}
      className={className}
      style={style}
      level={level}
      color={overrideColor || getColor(theme, color)}
      fontWeight={fontWeight ?? (bold ? StyledFontWeight.Bold : getFontWeight(theme, variant))}
      $span={span}>
      {children}
    </StyledText>
  );
});
