import { CustomerPortal } from '@stigg/js-client-sdk';
import { useEffect, useState } from 'react';
import logger from '../../../services/logger';
import { useStiggContext } from '../../StiggProvider';

type UseCustomerPortalProps = {
  resourceId?: string;
  productId?: string;
};

export function useCustomerPortal({ resourceId, productId }: UseCustomerPortalProps) {
  const { stigg, customerPortalUpdatedAt } = useStiggContext();
  const [isLoading, setIsLoading] = useState(false);
  const [customerPortal, setCustomerPortal] = useState<CustomerPortal | null>();

  useEffect(() => {
    const loadCustomerPortal = async () => {
      if (stigg.isCustomerLoaded) {
        try {
          setIsLoading(true);
          const customerPortalData = await stigg.getCustomerPortal({ resourceId, productId });
          setCustomerPortal(customerPortalData);
        } catch (err) {
          logger.error(`Failed to load customer portal ${(err as any)?.message}`, err as any);
        } finally {
          setIsLoading(false);
        }
      }
    };

    void loadCustomerPortal();
  }, [stigg, stigg.isCustomerLoaded, customerPortalUpdatedAt, resourceId, productId]);

  return {
    customerPortal,
    isLoading,
  };
}
