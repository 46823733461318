import styled from '@emotion/styled';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';

export const InputField = styled(TextField)<OutlinedInputProps>(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    height: '42px',
    borderRadius: theme.stigg.border.radius,
    fieldset: {
      borderColor: theme.stigg.palette.outlinedBorder,
    },
    '&:not(.Mui-focused):hover fieldset': {
      borderColor: theme.stigg.palette.outlinedRestingBorder,
    },
  },
  '& .MuiInputBase-input': {
    padding: '8px 12px',
    borderRadius: theme.stigg.border.radius,
    fontFamily: theme.stigg.typography.fontFamily,
    color: theme.stigg.palette.text.primary,
    ...theme.stigg.typography.body,
  },
  '& .Mui-error': {
    fontFamily: theme.stigg.typography.fontFamily,
  },
}));
