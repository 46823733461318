import { useCheckoutContext } from '../CheckoutProvider';
import { AddonsStepState } from './useAddonsStepModel';
import { PromotionCodeState } from './useCouponModel';
import { PaymentStepState } from './usePaymentStepModel';
import { PlanStepState } from './usePlanStepModel';

export type SubscriptionState = PlanStepState &
  PromotionCodeState &
  Pick<AddonsStepState, 'addons'> &
  Pick<PaymentStepState, 'billingAddress' | 'taxPercentage'>;

export function useSubscriptionModel(): SubscriptionState {
  const [{ planStep, addonsStep, promotionCode, paymentStep }] = useCheckoutContext();

  return {
    ...planStep,
    addons: addonsStep.addons,
    promotionCode,
    billingAddress: paymentStep.billingAddress,
    taxPercentage: paymentStep.taxPercentage,
  };
}
