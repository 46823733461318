import React from 'react';
import styled from '@emotion/styled/macro';
import { StyledButton } from '../common/StyledButton';
import { Typography } from '../../common/Typography';
import EditIcon from '../../../assets/edit-icon.svg';
import { CustomerPortalLocalization } from '../customerPortalTextOverrides';
import { SectionHeader } from '../common/SectionHeader';
import { SectionTitle } from '../common/SectionTitle';
import { OnManageClick } from '../CustomerPortalContainer';
import { CustomerPortalIntentionType } from '../types';

type SubscriptionsOverviewHeaderProps = {
  onManageSubscription: OnManageClick;
  hideManageButton: boolean;
  textOverrides: CustomerPortalLocalization;
};

const StyledEditIcon = styled(EditIcon)`
  path {
    stroke: ${({ theme }) => theme.stigg.palette.primary};
  }
`;

export function SubscriptionsOverviewHeader({
  onManageSubscription,
  hideManageButton,
  textOverrides,
}: SubscriptionsOverviewHeaderProps) {
  return (
    <SectionHeader className="stigg-customer-portal-overview-header" $disableMargin>
      <SectionTitle title="Subscription" className="stigg-overview-title" />
      {!hideManageButton && (
        <StyledButton
          className="stigg-manage-subscription-button"
          variant="outlined"
          onClick={() => onManageSubscription({ intentionType: CustomerPortalIntentionType.MANAGE_SUBSCRIPTION })}>
          <StyledEditIcon className="stigg-manage-subscription-button-image" />
          <Typography
            className="stigg-manage-subscription-button-text"
            color="primary.main"
            style={{ marginLeft: '8px' }}
            variant="body1">
            {textOverrides?.manageSubscription}
          </Typography>
        </StyledButton>
      )}
    </SectionHeader>
  );
}
