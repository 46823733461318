import { CustomerPortalPromotionalEntitlement } from '@stigg/js-client-sdk';
import moment from 'moment';
import React from 'react';
import styled from '@emotion/styled/macro';
import { Typography } from '../../../common/Typography';
import { useCustomerPortalContext } from '../../CustomerPortalProvider';

const Row = styled.div<{ $borderColor: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 64px;
  border-bottom: ${({ $borderColor }) => `1px solid ${$borderColor}`};
  padding: 16px 0;

  & > *:first-of-type {
    max-width: 60%;
  }
`;

function getPromotionTitle(promotionalEntitlement: CustomerPortalPromotionalEntitlement) {
  const { displayName, usageLimit, hasUnlimitedUsage } = promotionalEntitlement;

  if (hasUnlimitedUsage) {
    return `Unlimited ${displayName}`;
  }
  if (usageLimit) {
    return `${displayName} x ${promotionalEntitlement.usageLimit?.toLocaleString()}`;
  }

  return displayName;
}

export function Promotions() {
  const { customerPortal, textOverrides, theme } = useCustomerPortalContext();
  const promotionalEntitlements = customerPortal?.promotionalEntitlements;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography color="secondary">{textOverrides.promotionsSubtitle}</Typography>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Row $borderColor={theme.borderColor}>
          <Typography variant="body1" bold>
            Feature
          </Typography>
          <Typography variant="body1" bold>
            Expiration date
          </Typography>
        </Row>
        {promotionalEntitlements?.map((promotionalEntitlement, index) => (
          <Row key={`${promotionalEntitlement.displayName}-${index}`} $borderColor={theme.borderColor}>
            <Typography variant="body1">{getPromotionTitle(promotionalEntitlement)}</Typography>
            <Typography variant="body1" color="disabled">
              {promotionalEntitlement.endDate
                ? moment.utc(promotionalEntitlement.endDate).format('MMM Do, YYYY')
                : 'None'}
            </Typography>
          </Row>
        ))}
      </div>
    </div>
  );
}
