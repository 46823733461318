import { MeterType, PricingType, SubscriptionStatus } from '@stigg/js-client-sdk';
import React from 'react';
import { compact, keyBy } from 'lodash';
import { Minus, Plus } from 'react-feather';
import Grid from '@mui/material/Grid';
import { useCustomerPortalContext } from '../CustomerPortalProvider';
import { FeatureUsage } from './featureUsage/FeatureUsage';
import { Footer, CustomerUsageLoader } from './CustomerUsageData.style';
import { SectionContainer } from '../common/SectionContainer';
import { SectionHeader } from '../common/SectionHeader';
import { SectionTitle } from '../common/SectionTitle';
import { StyledButton } from '../common/StyledButton';
import { FilterEntitlementsFn, OnBuyMoreCallbackFn } from '../types';
import { OnManageClick } from '../CustomerPortalContainer';

const MAX_BOXES = 6;

export type CustomerUsageDataProps = {
  onManageSubscription?: OnManageClick;
  onBuyMore?: OnBuyMoreCallbackFn;
  filterEntitlements?: FilterEntitlementsFn;
};

export function CustomerUsageData({ onManageSubscription, onBuyMore, filterEntitlements }: CustomerUsageDataProps) {
  const [showAll, setShowAll] = React.useState(false);
  const { customerPortal, isLoading, textOverrides, theme } = useCustomerPortalContext();
  const isLoadingData = isLoading || !customerPortal;
  const { entitlements, subscriptions } = customerPortal || {};
  const meteredEntitlements = entitlements?.filter(
    (entitlement) =>
      entitlement.feature?.meterType === MeterType.Fluctuating ||
      entitlement.feature?.meterType === MeterType.Incremental,
  );
  const activeSubscription = subscriptions?.find(({ status }) => status === SubscriptionStatus.Active);
  const hasCustomSubscription = activeSubscription?.pricingType === PricingType.Custom;
  const canUpgradeSubscription = !!customerPortal?.canUpgradeSubscription;

  const subscriptionPriceByFeature = keyBy(activeSubscription?.prices, 'feature.refId');
  const entitlementByFeature = keyBy(meteredEntitlements, 'feature.refId');

  // sort features by prices first, then other entitlements
  const priceEntitlements = compact(
    activeSubscription?.prices.map(
      (subscriptionPrice) => subscriptionPrice.feature && entitlementByFeature[subscriptionPrice.feature.refId],
    ),
  );
  const otherEntitlements = compact(
    meteredEntitlements?.filter((entitlement) => !subscriptionPriceByFeature[entitlement.feature!.refId]),
  );
  const sortedEntitlements = [...priceEntitlements, ...otherEntitlements];

  const filteredEntitlements = filterEntitlements ? filterEntitlements(sortedEntitlements) : sortedEntitlements;

  // 4 -> 3 per row, 6 -> 2 per row
  const xs = filteredEntitlements.length > 2 ? 4 : 6;

  const entitlementsToShow = showAll ? filteredEntitlements : filteredEntitlements.slice(0, MAX_BOXES);

  const toggleShowAll = () => setShowAll((prevState) => !prevState);

  if (!isLoadingData && !meteredEntitlements?.length) {
    return null;
  }

  return (
    <SectionContainer
      className="stigg-subscription-usage-layout"
      $backgroundColor={theme.backgroundColor}
      $borderColor={theme.borderColor}>
      <SectionHeader>
        <SectionTitle
          title={textOverrides.usageTabTitle}
          className="stigg-customer-portal-usage-section-title"
          isLoading={isLoadingData}
        />
      </SectionHeader>

      {isLoadingData ? (
        <CustomerUsageLoader />
      ) : (
        <>
          <Grid container spacing={4}>
            {entitlementsToShow.map((entitlement) => (
              <Grid
                className={`stigg-entitlement-usage-${entitlement.feature!.refId}`}
                key={entitlement.feature!.refId}
                item
                xs={xs}>
                <FeatureUsage
                  key={entitlement.feature!.refId}
                  subscriptionPrice={subscriptionPriceByFeature[entitlement.feature!.refId]}
                  entitlement={entitlement}
                  onManageSubscription={onManageSubscription}
                  onBuyMore={onBuyMore}
                  hasCustomSubscription={hasCustomSubscription}
                  canUpgradeSubscription={canUpgradeSubscription}
                />
              </Grid>
            ))}
          </Grid>

          {filteredEntitlements.length > MAX_BOXES && (
            <Footer>
              <StyledButton
                className="stigg-usage-toggle-many-button"
                variant="text"
                startIcon={showAll ? <Minus /> : <Plus />}
                onClick={toggleShowAll}>
                {showAll ? 'Show less' : 'Show more'}
              </StyledButton>
            </Footer>
          )}
        </>
      )}
    </SectionContainer>
  );
}
