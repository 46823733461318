import React from 'react';
import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { CheckoutStatePlan, Subscription } from '@stigg/js-client-sdk';
import { StyledArrowRightIcon } from './StyledArrow';
import { Typography } from '../../common/Typography';
import { CheckoutLocalization } from '../configurations/textOverrides';
import { CheckoutContainerProps } from '../CheckoutContainer';
import { ChangePlanButton } from './ChangePlanButton';

const DowngradeToFreePlansContainer = styled(Box)`
  display: flex;
`;

const DowngradeToFreeAlert = styled(Alert)`
  margin-bottom: 16px;
`;

export const DowngradeToFreePlanBox = styled(Box)`
  padding: 16px;
  border-radius: 10px;
  width: 100%;
  border: ${({ theme }) => `1px solid ${theme.stigg.palette.outlinedBorder}`};
`;

export const DowngradeToFreeContent = ({
  headerText,
  planName,
  priceText,
}: {
  headerText: string;
  planName: string;
  priceText: string;
}) => {
  return (
    <DowngradeToFreePlanBox className="stigg-checkout-downgrade-to-free-container">
      <Typography
        className="stigg-checkout-downgrade-to-free-text-header"
        style={{ opacity: 0.8 }}
        variant="caption"
        color="disabled">
        {headerText}
      </Typography>
      <Typography className="stigg-checkout-downgrade-to-free-plan-name" bold variant="h3" color="primary">
        {planName}
      </Typography>
      <Typography className="stigg-checkout-downgrade-to-free-price-text" color="secondary">
        {priceText}
      </Typography>
    </DowngradeToFreePlanBox>
  );
};

type DowngradeToFreePlanProps = {
  checkoutLocalization: CheckoutLocalization;
  activeSubscription: Subscription;
  freePlan: CheckoutStatePlan;
  allowChangePlan?: boolean;
} & Pick<CheckoutContainerProps, 'onChangePlan'>;

export const DowngradeToFreePlan = ({
  checkoutLocalization,
  activeSubscription,
  freePlan,
  allowChangePlan = false,
  onChangePlan,
}: DowngradeToFreePlanProps) => {
  let alertAction;
  if (allowChangePlan && onChangePlan) {
    alertAction = (
      <ChangePlanButton
        onClick={() => {
          onChangePlan({ currentPlan: freePlan });
        }}
        checkoutLocalization={checkoutLocalization}
        size="small"
      />
    );
  }

  const paidBillingPeriod =
    activeSubscription.prices.length > 0 ? activeSubscription.prices[0].billingPeriod : undefined;

  return (
    <>
      <DowngradeToFreeAlert action={alertAction} className="stigg-checkout-downgrade-to-free-alert" severity="info">
        <Typography span color="secondary">
          {checkoutLocalization.downgradeToFree.alertText({ plan: activeSubscription.plan })}
        </Typography>
      </DowngradeToFreeAlert>

      <DowngradeToFreePlansContainer className="stigg-checkout-downgrade-to-free-plans-container">
        <DowngradeToFreeContent
          headerText={checkoutLocalization.downgradeToFree.paidPlanHeader({ plan: activeSubscription.plan })}
          planName={checkoutLocalization.downgradeToFree.paidPlanName({ plan: activeSubscription.plan })}
          priceText={checkoutLocalization.downgradeToFree.paidPlanPriceText({
            plan: activeSubscription.plan,
            billingPeriod: paidBillingPeriod,
          })}
        />

        <StyledArrowRightIcon
          className="stigg-checkout-downgrade-to-free-arrow"
          style={{ margin: 'auto 16px', minWidth: '16px' }}
        />

        <DowngradeToFreeContent
          headerText={checkoutLocalization.downgradeToFree.freePlanHeader({ plan: freePlan })}
          planName={checkoutLocalization.downgradeToFree.freePlanName({ plan: freePlan })}
          priceText={checkoutLocalization.downgradeToFree.freePlanPriceText({ plan: freePlan })}
        />
      </DowngradeToFreePlansContainer>
    </>
  );
};
