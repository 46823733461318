import React from 'react';
import { StripeAddressElementChangeEvent } from '@stripe/stripe-js';
import Grid from '@mui/material/Grid';
import { BillingAddress } from '@stigg/js-client-sdk';
import { AddressElement, PaymentElement } from '@stripe/react-stripe-js';
import { Typography } from '../../../../common/Typography';
import { useCheckoutModel, usePaymentStepModel } from '../../../hooks';
import { CheckoutContainerProps } from '../../../CheckoutContainer';

export function StripePaymentForm({
  onBillingAddressChange,
  collectPhoneNumber,
}: Pick<CheckoutContainerProps, 'onBillingAddressChange' | 'collectPhoneNumber'>) {
  const { checkoutState, checkoutLocalization, widgetState, setWidgetReadOnly } = useCheckoutModel();
  const { setBillingAddress } = usePaymentStepModel();
  const { customer, configuration } = checkoutState || {};
  const { readOnly } = widgetState;
  const shouldCollectPhoneNumber = !!(collectPhoneNumber ?? configuration?.content?.collectPhoneNumber);

  const handleAddressChange = (args: StripeAddressElementChangeEvent) => {
    if (!args.complete) {
      return;
    }

    const { postal_code: postalCode, ...addressFields } = args.value.address;
    const billingAddress: BillingAddress = {
      postalCode,
      ...addressFields,
    };

    setWidgetReadOnly(true);
    setBillingAddress(billingAddress);

    if (onBillingAddressChange) {
      const callExternalBillingAddressChanged = async () => {
        await onBillingAddressChange({ billingAddress });
        setWidgetReadOnly(false);
      };

      void callExternalBillingAddressChanged();
    } else {
      setWidgetReadOnly(false);
    }
  };

  return (
    <Grid flexDirection="column" container gap={3} padding="16px" sx={{ pointerEvents: readOnly ? 'none' : undefined }}>
      <Grid flexDirection="column" container gap={2}>
        <Typography variant="h6">{checkoutLocalization.newPaymentMethodBillingAddressTitle}</Typography>
        <AddressElement
          onChange={handleAddressChange}
          options={{
            mode: 'billing',
            fields: { phone: shouldCollectPhoneNumber ? 'always' : 'auto' },
            defaultValues: {
              ...(customer?.name && { name: customer.name }),
            },
          }}
        />
      </Grid>
      <Grid flexDirection="column" container gap={2}>
        <Typography variant="h6">{checkoutLocalization.newPaymentMethodCardTitle}</Typography>
        <PaymentElement
          onChange={() => {}}
          onReady={() => {}}
          options={{
            readOnly,
            terms: { card: 'never' },
            defaultValues: {
              billingDetails: {
                ...(customer?.name && { name: customer.name }),
                ...(customer?.email && { email: customer.email }),
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
