import React from 'react';
import styled from '@emotion/styled/macro';
import Button from '@mui/material/Button';
import { Typography } from '../../common/Typography';

const ContactCustomerSupportLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 8px;
`;

const ContactSupportButton = styled(Button)`
  width: fit-content;
  &.MuiButton-root {
    padding: 0;
    &:hover {
      background: none;
    }
  }
`;

type ContactCustomerSupportProps = {
  onContactSupport?: () => void;
  label: string;
  linkLabel: string;
};

export function ContactCustomerSupport({ onContactSupport, label, linkLabel }: ContactCustomerSupportProps) {
  const contactSupportText = (
    <Typography color={onContactSupport ? 'primary.main' : 'secondary'}>{linkLabel}</Typography>
  );
  return (
    <ContactCustomerSupportLayout className="stigg-contact-support-layout">
      <Typography className="stigg-contact-support-label" color="secondary">
        {label}
      </Typography>
      {onContactSupport ? (
        <ContactSupportButton
          className="stigg-contact-support-button"
          style={{ textTransform: 'none' }}
          variant="text"
          onClick={onContactSupport}>
          {contactSupportText}
        </ContactSupportButton>
      ) : (
        contactSupportText
      )}
    </ContactCustomerSupportLayout>
  );
}
