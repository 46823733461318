import React from 'react';
import { ArrowDown } from 'react-feather';
import Grid from '@mui/material/Grid';
import {
  CustomerPortalSubscription,
  CustomerPortalSubscriptionScheduledUpdateDataFragment,
  SubscriptionScheduleType,
  BillingPeriod,
} from '@stigg/js-client-sdk';
import { compact, map } from 'lodash';
import { Typography } from '../../../common/Typography';
import { BillingPeriodChangeVariables, UnitAmountChangeVariables } from '../../../../types';

export type SubscriptionScheduledUpdateRowProps = {
  scheduleUpdate: CustomerPortalSubscriptionScheduledUpdateDataFragment;
};

function SubscriptionScheduledUpdateDowngradeRow({ scheduleUpdate }: SubscriptionScheduledUpdateRowProps) {
  const { targetPackage } = scheduleUpdate;
  return (
    <Grid container alignItems="center">
      <Grid>
        <ArrowDown color="#D94052" height={20} className="stigg-scheduled-updates-arrow-down" />
      </Grid>
      <Grid item>
        <Typography className="stigg-scheduled-updates-description" variant="body1">
          Downgrade to the <b>{targetPackage?.displayName}</b> plan
        </Typography>
      </Grid>
    </Grid>
  );
}

function SubscriptionScheduledUpdateBillingPeriodChangeRow({ scheduleUpdate }: SubscriptionScheduledUpdateRowProps) {
  const { scheduleVariables } = scheduleUpdate;
  const { billingPeriod } = scheduleVariables as BillingPeriodChangeVariables;
  const currentBillingPeriod = billingPeriod === BillingPeriod.Monthly ? 'annually' : 'monthly';
  const nextBillingPeriod = billingPeriod === BillingPeriod.Monthly ? 'monthly' : 'annually';

  return (
    <Grid container alignItems="center">
      <Grid>
        <ArrowDown color="#D94052" height={20} className="stigg-scheduled-updates-arrow-down" />
      </Grid>
      <Grid item>
        <Typography className="stigg-scheduled-updates-description" variant="body1">
          Change from an {currentBillingPeriod} to a <b>{nextBillingPeriod}</b> billing cycle
        </Typography>
      </Grid>
    </Grid>
  );
}

function SubscriptionScheduledUpdateUnitQuantityChangeRow({
  subscription,
  scheduleUpdate,
}: SubscriptionScheduledUpdateRowProps & { subscription: CustomerPortalSubscription }) {
  const { scheduleVariables } = scheduleUpdate;
  const { newUnitAmount, featureId } = scheduleVariables as UnitAmountChangeVariables;
  const feature = compact(map(subscription.prices, 'feature')).find(({ id }) => id === featureId);

  if (!feature) {
    return null;
  }

  const { featureUnits, featureUnitsPlural } = feature;
  const newQuantityUnits = newUnitAmount === 1 ? featureUnits : featureUnitsPlural;

  return (
    <Grid container alignItems="flex-start" flexWrap="nowrap">
      <Grid>
        <ArrowDown color="#D94052" height={20} className="stigg-scheduled-updates-arrow-down" />
      </Grid>
      <Grid item>
        <Typography className="stigg-scheduled-updates-description" variant="body1">
          Reduce the <b>{featureUnits}</b> quantity to {newUnitAmount} {newQuantityUnits}
        </Typography>
      </Grid>
    </Grid>
  );
}

export function SubscriptionScheduledUpdateRow({
  scheduleUpdate,
  subscription,
}: {
  scheduleUpdate: CustomerPortalSubscriptionScheduledUpdateDataFragment;
  subscription: CustomerPortalSubscription;
}) {
  switch (scheduleUpdate.subscriptionScheduleType) {
    case SubscriptionScheduleType.Downgrade: {
      return <SubscriptionScheduledUpdateDowngradeRow scheduleUpdate={scheduleUpdate} />;
    }

    case SubscriptionScheduleType.BillingPeriod: {
      return <SubscriptionScheduledUpdateBillingPeriodChangeRow scheduleUpdate={scheduleUpdate} />;
    }

    case SubscriptionScheduleType.UnitAmount: {
      return (
        <SubscriptionScheduledUpdateUnitQuantityChangeRow scheduleUpdate={scheduleUpdate} subscription={subscription} />
      );
    }

    default: {
      return null;
    }
  }
}
