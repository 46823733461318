import { ApplySubscriptionResults, PaymentCollection } from '@stigg/js-client-sdk';
import { Stripe, StripeElements } from '@stripe/stripe-js';

type StripeElementsProps = {
  stripe: Stripe | null;
  elements: StripeElements | null;
};

const FAILED_PAYMENT_COLLECTION_STATUSES = [PaymentCollection.Failed, PaymentCollection.Processing];

export async function handleStripeFormValidations({ elements }: Pick<StripeElementsProps, 'elements'>) {
  if (!elements) {
    const errorMessage = 'Stripe elements not initialized';
    console.error(errorMessage);
    return { success: false, errorMessage };
  }

  const { error: elementsError } = await elements.submit();

  if (elementsError) {
    console.log(elementsError.message);
    return { success: false, errorMessage: elementsError.message || '' };
  }

  return { success: true };
}

export async function handleStripeNextAction({
  applySubscriptionResults,
  stripe,
}: {
  applySubscriptionResults: ApplySubscriptionResults;
  stripe: Stripe | null;
}) {
  const subscription = applySubscriptionResults?.subscription;
  const paymentIntentClientSecret = subscription?.latestInvoice?.paymentSecret;
  const paymentCollection = subscription?.paymentCollection;

  if (stripe && paymentIntentClientSecret && paymentCollection === PaymentCollection.ActionRequired) {
    const { error: NextActionError } = await stripe.handleNextAction({ clientSecret: paymentIntentClientSecret });
    if (NextActionError) {
      return { errorMessage: NextActionError.message || '' };
    }
  } else if (paymentCollection && FAILED_PAYMENT_COLLECTION_STATUSES.includes(paymentCollection)) {
    return { errorMessage: subscription?.latestInvoice?.errorMessage || 'An error occurred - try again later' };
  }

  return { subscription };
}

async function handleStripeSetup({
  stripe,
  elements,
  clientSecret,
}: {
  stripe: Stripe;
  elements: StripeElements;
  clientSecret: string;
}) {
  let newPaymentMethodId: string | undefined;
  let setupErrorMessage: string | undefined;

  const { error: setupError } = await stripe.confirmSetup({
    elements,
    confirmParams: { return_url: window.location.href },
    redirect: 'if_required',
  });

  if (setupError) {
    if (setupError.type === 'card_error' || setupError.type === 'validation_error') {
      if (setupError.type === 'card_error') {
        // Set some error message
        setupErrorMessage = setupError.message || '';
      }
    } else {
      setupErrorMessage = 'An unexpected error occurred.';
    }
  } else {
    try {
      const { setupIntent } = await stripe.retrieveSetupIntent(clientSecret);
      if (setupIntent?.payment_method) {
        newPaymentMethodId = setupIntent.payment_method as string;
      }
    } catch (e) {
      setupErrorMessage = 'An unexpected error occurred.';
      console.error(e);
    }
  }

  return { newPaymentMethodId, setupErrorMessage };
}

export async function handleNewPaymentMethod({
  stripe,
  elements,
  setupIntentClientSecret,
}: {
  stripe: Stripe | null;
  elements: StripeElements | null;
  setupIntentClientSecret?: string;
}): Promise<{ success: boolean; errorMessage?: string; paymentMethodId?: string }> {
  if (!stripe || !elements || !setupIntentClientSecret) {
    return { success: false };
  }

  const stripeFormValidationsResults = await handleStripeFormValidations({ elements });
  if (!stripeFormValidationsResults.success) {
    return { success: false, errorMessage: stripeFormValidationsResults.errorMessage };
  }

  const { newPaymentMethodId, setupErrorMessage } = await handleStripeSetup({
    stripe,
    elements,
    clientSecret: setupIntentClientSecret,
  });

  if (setupErrorMessage || !newPaymentMethodId) {
    return { success: false, errorMessage: setupErrorMessage };
  }

  return { success: true, paymentMethodId: newPaymentMethodId };
}
