import React, { FC, PropsWithChildren } from 'react';
import { Skeleton } from '../../components/Skeletons.style';

export const WithSkeleton: FC<
  PropsWithChildren<{
    isLoading: boolean;
    width?: number | string;
    height?: number | string;
  }>
> = ({ isLoading, width = 50, height = 16, children }) => {
  if (isLoading) {
    return <Skeleton width={width} height={height} />;
  }

  return <>{children}</>;
};
