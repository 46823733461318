import styled from '@emotion/styled/macro';
import React, { ReactNode } from 'react';
import {
  BillingModel,
  CustomerPortalSubscription,
  PricingType,
  SubscriptionScheduleType,
  SubscriptionStatus,
} from '@stigg/js-client-sdk';
import { CustomerPortalTheme } from '../../customerPortalTheme';
import { NextEstimatedBill } from './NextEstimatedBill';
import { NoUpcomingBilling } from './NoUpcomingBilling';
import { ContactCustomerSupport } from '../ContactCustomerSupport';
import { CustomerPortalLocalization } from '../../customerPortalTextOverrides';

const Container = styled.div<{ $borderColor: string }>`
  max-height: 278px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.stigg.palette.backgroundSection};
  border: ${({ $borderColor }) => `1px solid ${$borderColor}`};
  padding: 32px;
  gap: 16px;
`;

export type UpcomingBillingProps = {
  subscription: CustomerPortalSubscription;
  theme: CustomerPortalTheme;
  textOverrides: CustomerPortalLocalization;
  onContactSupport?: () => Promise<void> | void;
};

export function UpcomingBilling({ subscription, theme, textOverrides, onContactSupport }: UpcomingBillingProps) {
  const { pricingType } = subscription;
  let content: ReactNode;
  const noUsageBasedPrices =
    subscription.prices?.every((price) => price.billingModel !== BillingModel.UsageBased) ?? true;
  const scheduledDowngradeToFree = subscription.scheduledUpdates?.find(
    ({ subscriptionScheduleType, targetPackage }) =>
      subscriptionScheduleType === SubscriptionScheduleType.Downgrade &&
      targetPackage?.pricingType === PricingType.Free,
  );

  if (pricingType === PricingType.Free) {
    content = <NoUpcomingBilling description="You're on a free plan, so you don’t have any upcoming charges" />;
  } else if (pricingType === PricingType.Paid) {
    if (scheduledDowngradeToFree && noUsageBasedPrices) {
      content = (
        <NoUpcomingBilling description="Your plan will be downgraded to a free plan, so you don’t have any upcoming charges" />
      );
    } else if (subscription.status === SubscriptionStatus.InTrial) {
      content = (
        <NoUpcomingBilling
          description={`You’re on a free trial of the ${subscription.planName} plan, so you don’t have any upcoming charges`}
        />
      );
    } else {
      const billingPeriod = subscription.prices?.[0]?.billingPeriod;
      const rangeEnd = subscription.billingPeriodRange?.end;
      const total = subscription.totalPrice?.total;

      if (billingPeriod && rangeEnd && total) {
        content = (
          <NextEstimatedBill
            billingPeriod={billingPeriod}
            rangeEnd={rangeEnd}
            amount={total.amount}
            currency={total.currency}
          />
        );
      }
    }
  } else if (pricingType === PricingType.Custom) {
    content = (
      <ContactCustomerSupport
        onContactSupport={onContactSupport}
        label={textOverrides.contactSupportTitle}
        linkLabel={textOverrides.contactSupportLink}
      />
    );
  }

  return (
    <Container className="stigg-subscription-total-price-layout" $borderColor={theme.borderColor}>
      {content}
    </Container>
  );
}
