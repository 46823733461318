import React, { useState } from 'react';
import { BillingPeriod, Customer, PricingType, Subscription } from '@stigg/js-client-sdk';
import { isFunction } from 'lodash';
import ClipLoader from 'react-spinners/ClipLoader';
import styled from '@emotion/styled/macro';
import { css, useTheme } from '@emotion/react';
import { PaywallPlan, SubscribeIntentionType } from './types';
import { PaywallLocalization } from './paywallTextOverrides';
import { flexLayoutMapper } from '../../theme/getResolvedTheme';
import { Typography } from '../common/Typography';
import { getSubscriptionScheduleUpdateTexts } from '../utils/getSubscriptionScheduleUpdateTexts';
import { compareSelectedTierToCurrentTier, PriceTierComparison } from '../utils/priceTierUtils';

const LoadingIndicator = styled(ClipLoader)`
  margin-left: 10px;
`;

const OfferingButton = styled.button<{ $disabled?: boolean }>`
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  padding: 8px 12px;
  background-color: ${({ theme }) => theme.stigg.palette.backgroundButton};
  border: ${({ theme }) => `1px solid ${theme.stigg.palette.outlinedRestingBorder}`};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 42px;
  min-width: 120px;

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
    `}

  &:hover {
    background-color: ${({ theme, $disabled }) =>
      $disabled ? theme.stigg.palette.backgroundButton : theme.stigg.palette.outlinedHoverBackground};
  }
`;

const ButtonText = styled(Typography)`
  margin: 0;
  font-size: 15px;
`;

const ScheduledUpdateText = styled(Typography)`
  min-height: 87px;
`;

const CancelScheduledUpdateButton = styled.button<{ $disabled?: boolean }>`
  background: none;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.stigg.palette.text.secondary};

  text-decoration: underline;
  cursor: pointer;
`;

const TrialDaysLeft = styled(Typography)`
  margin-top: 8px;
  margin-bottom: 17px;
  min-height: 20px;
`;

const ButtonLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  align-self: ${({ theme }) => flexLayoutMapper(theme.stigg.layout.ctaAlignment)};
  width: 100%;
`;

type PlanOfferingButtonProps = {
  isNextPlan?: boolean;
  customer: Customer | null;
  plan: PaywallPlan;
  currentSubscription: Subscription | null;
  billingPeriod: BillingPeriod;
  isCustomerOnTrial: boolean;
  paywallLocale: PaywallLocalization;
  onPlanSelected: (intentionType: SubscribeIntentionType) => void | Promise<void>;
  withTrialLeftRow: boolean;
  perUnitQuantityByFeature: Record<string, number>;
};

export function PlanOfferingButton({
  isNextPlan,
  customer,
  plan,
  billingPeriod,
  isCustomerOnTrial,
  onPlanSelected,
  paywallLocale,
  withTrialLeftRow,
  currentSubscription,
  perUnitQuantityByFeature,
}: PlanOfferingButtonProps) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const {
    currentPlan,
    custom,
    downgrade,
    startTrial,
    upgrade,
    startNew,
    switchToBillingPeriod,
    cancelScheduledUpdate,
  } = paywallLocale.planCTAButton;
  const resolvedDowngrade = isFunction(downgrade) ? downgrade(plan) : downgrade;
  const resolvedUpgrade = isFunction(upgrade) ? upgrade(plan) : upgrade;

  const buttonProps: {
    title: string;
    disabled: boolean;
    intentionType: SubscribeIntentionType;
  } = {
    title: customer ? (plan.isLowerThanCurrentPlan ? resolvedDowngrade : resolvedUpgrade) : startNew,
    disabled: false,
    intentionType: plan.isLowerThanCurrentPlan
      ? SubscribeIntentionType.DOWNGRADE_PLAN
      : isCustomerOnTrial
      ? SubscribeIntentionType.UPGRADE_TRIAL_TO_PAID
      : SubscribeIntentionType.UPGRADE_PLAN,
  };
  const isCustomPrice = plan.pricingType === PricingType.Custom;

  const { line1: scheduledUpdateText } = getSubscriptionScheduleUpdateTexts({
    scheduledUpdate: plan.scheduledUpdate,
    options: { withDate: true },
  });

  if (plan.isTriable && !plan.isCurrentCustomerPlan && !isCustomerOnTrial) {
    buttonProps.title = startTrial(plan);
    buttonProps.intentionType = SubscribeIntentionType.START_TRIAL;
  } else if (plan.isCurrentCustomerPlan && !isCustomerOnTrial) {
    const isSameBillingPeriod = billingPeriod === plan.currentCustomerPlanBillingPeriod;

    if (
      isSameBillingPeriod ||
      (plan.pricingType && [PricingType.Free, PricingType.Custom].includes(plan.pricingType))
    ) {
      const planComparison = compareSelectedTierToCurrentTier(perUnitQuantityByFeature, currentSubscription);
      switch (planComparison) {
        case PriceTierComparison.Lower:
          buttonProps.intentionType = SubscribeIntentionType.CHANGE_UNIT_QUANTITY;
          buttonProps.title = resolvedDowngrade;
          break;
        case PriceTierComparison.Higher:
          buttonProps.intentionType = SubscribeIntentionType.CHANGE_UNIT_QUANTITY;
          buttonProps.title = resolvedUpgrade;
          break;

        default:
          buttonProps.title = currentPlan;
          buttonProps.disabled = true;
          break;
      }
    } else {
      buttonProps.title = switchToBillingPeriod(billingPeriod);
      buttonProps.intentionType = SubscribeIntentionType.CHANGE_BILLING_PERIOD;
    }
  } else if (isCustomPrice) {
    buttonProps.title = typeof custom === 'function' ? custom({ plan, selectedBillingPeriod: billingPeriod }) : custom;
    buttonProps.intentionType = SubscribeIntentionType.REQUEST_CUSTOM_PLAN_ACCESS;
  }

  if (isNextPlan && plan.scheduledUpdate) {
    buttonProps.disabled = false;
    buttonProps.title = cancelScheduledUpdate;
    buttonProps.intentionType = SubscribeIntentionType.CANCEL_SCHEDULED_UPDATES;
  }

  const isDisabled = isLoading || buttonProps.disabled;
  return (
    <>
      <ButtonLayout className="stigg-paywall-plan-button-layout">
        {isNextPlan && scheduledUpdateText ? (
          <ScheduledUpdateText color="secondary" variant="body1" className="stigg-paywall-schedule-update-text">
            {scheduledUpdateText}{' '}
            <CancelScheduledUpdateButton
              color="secondary"
              onClick={() => {
                setIsLoading(true);
                Promise.resolve(onPlanSelected(buttonProps.intentionType)).finally(() => setIsLoading(false));
              }}>
              {buttonProps.title}
            </CancelScheduledUpdateButton>
          </ScheduledUpdateText>
        ) : (
          <>
            <OfferingButton
              className="stigg-paywall-plan-button"
              data-testid={`paywall-card-cta-${plan.displayName}`}
              disabled={isDisabled}
              $disabled={isDisabled}
              onClick={() => {
                setIsLoading(true);
                Promise.resolve(onPlanSelected(buttonProps.intentionType)).finally(() => setIsLoading(false));
              }}>
              <ButtonText className="stigg-paywall-plan-button-text" variant="h6" color="primary.main">
                {buttonProps.title}
              </ButtonText>
              {isLoading && <LoadingIndicator color={theme.stigg.palette.text.disabled} loading size={16} />}
            </OfferingButton>

            {!withTrialLeftRow ? (
              <div style={{ height: '20px' }} />
            ) : (
              <TrialDaysLeft className="stigg-trial-days-left-text" variant="h6" color="secondary">
                {plan.isCurrentCustomerPlan && plan.trialDaysLeft && (
                  <>
                    <b>{plan.trialDaysLeft} days</b> left on your free trial
                  </>
                )}
              </TrialDaysLeft>
            )}
          </>
        )}
      </ButtonLayout>
    </>
  );
}
