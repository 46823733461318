import React, { useEffect, useState, PropsWithChildren, useContext, useRef } from 'react';
import * as StiggJs from '@stigg/js-client-sdk';
import Stigg, { ClientConfiguration, EntitlementsFallback } from '@stigg/js-client-sdk';
import { SkeletonTheme } from 'react-loading-skeleton';
import { CustomizedTheme } from '../theme/Theme';
import logger from '../services/logger';
import { name as CLIENT_NAME, version as CLIENT_VERSION } from '../../package.json';

export interface StiggContextValue {
  stigg: StiggJs.StiggClient;
  isInitialized: boolean;
  locale: string;
  updatedAt?: Date;
  customerPortalUpdatedAt?: Date;
  refreshData: () => Promise<void>;
  theme?: CustomizedTheme;
}

export const StiggContext = React.createContext<StiggContextValue | null>(null);
StiggContext.displayName = 'StiggContext';

export type StiggProviderProps = {
  apiKey: string;
  baseUri?: string;
  baseEdgeUri?: string;
  enableEdge?: boolean;
  entitlementsFallback?: EntitlementsFallback;
  customerId?: string;
  customerToken?: string;
  resourceId?: string;
  theme?: CustomizedTheme;
  locale?: string;
  cacheTtlMs?: number;
  /** @deprecated not longer in use */
  useEntitlementPolling?: boolean;
  /** @deprecated not longer in use */
  entitlementPollingInterval?: number;
  stiggClient?: StiggJs.StiggClient;
  clientName?: string;
  clientVersion?: string;
};

const DEFAULT_LOCALE = 'en-US';

export const StiggProvider: React.FC<PropsWithChildren<StiggProviderProps>> = ({
  apiKey,
  baseUri,
  baseEdgeUri,
  enableEdge,
  customerId,
  customerToken,
  resourceId,
  theme,
  locale,
  cacheTtlMs,
  useEntitlementPolling,
  entitlementPollingInterval,
  entitlementsFallback,
  stiggClient,
  children,
  clientName = CLIENT_NAME,
  clientVersion = CLIENT_VERSION,
}) => {
  const currentApiKey = useRef(apiKey);
  const initializeParams: ClientConfiguration = {
    apiKey,
    customerId,
    customerToken,
    resourceId,
    baseUri,
    baseEdgeUri,
    enableEdge,
    entitlementsFallback,
    cacheTtlMs,
    useEntitlementPolling,
    entitlementPollingInterval,
    clientName,
    clientVersion,
  };
  const [ctx, setContext] = useState<StiggContextValue>(() => ({
    stigg:
      stiggClient ??
      Stigg.initialize({
        ...initializeParams,
        lazyLoad: true,
      }),
    locale: locale || DEFAULT_LOCALE,
    isInitialized: false,
    theme,
    refreshData: async () => {
      setContext((ctx) => ({ ...ctx, customerPortalUpdatedAt: new Date() }));
      await ctx.stigg.refresh();
    },
  }));

  useEffect(() => {
    let isMounted = true;

    const safeSetContext = (setContextFunc: React.SetStateAction<StiggContextValue>) => {
      if (isMounted) {
        setContext(setContextFunc);
      }
    };

    const onEntitlementsUpdated = () => safeSetContext((ctx) => ({ ...ctx, updatedAt: new Date() }));

    const setStiggInstance = (stigg: StiggJs.StiggClient) =>
      safeSetContext((ctx) => {
        if (ctx.stigg) {
          ctx.stigg.removeListener('entitlementsUpdated', onEntitlementsUpdated);
        }
        stigg.addListener('entitlementsUpdated', onEntitlementsUpdated);

        return {
          ...ctx,
          stigg,
          isInitialized: true,
          updatedAt: new Date(),
        };
      });

    const loadStiggClient = async () => {
      let stiggClient = ctx.stigg;
      if (apiKey !== currentApiKey.current) {
        logger.log('Updating stigg client', { apiKey });
        currentApiKey.current = apiKey;
        stiggClient = Stigg.initialize({
          ...initializeParams,
        });
        safeSetContext((ctx) => ({ ...ctx, isInitialized: false, updatedAt: new Date() }));
      }
      await stiggClient.waitForInitialization();
      setStiggInstance(stiggClient);
    };

    void loadStiggClient();

    return () => {
      isMounted = false;
      ctx.stigg.removeListener('entitlementsUpdated', onEntitlementsUpdated);
      ctx.stigg.clearCustomer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey]);

  useEffect(() => {
    const stiggClient = ctx.stigg;
    if (customerId) {
      void stiggClient.setCustomerId(customerId, customerToken, resourceId);
    } else {
      stiggClient.clearCustomer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId, customerToken, resourceId]);

  useEffect(() => {
    setContext((ctx) => ({ ...ctx, theme }));
  }, [theme]);

  return (
    <StiggContext.Provider value={ctx}>
      <SkeletonTheme baseColor="#F5F6F9" highlightColor="#e5e6ea">
        {children}
      </SkeletonTheme>
    </StiggContext.Provider>
  );
};

export const useStiggContext = (): StiggContextValue => {
  const ctx = useContext(StiggContext);
  if (!ctx) {
    throw new Error('Could not find Stigg context; You need to wrap your app in an <StiggProvider> component.');
  }
  return ctx;
};
