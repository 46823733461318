import React from 'react';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import styled from '@emotion/styled/macro';
import { FlexedSkeleton, FlexedSkeletonContainer, Skeleton, SkeletonsContainer } from './Skeletons.style';

const ContentContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

export function ContentLoadingSkeleton() {
  return (
    <ContentContainer container>
      <SkeletonsContainer item $gap={48}>
        <Skeleton width={120} height={20} />
        <Skeleton width={120} height={20} />
      </SkeletonsContainer>

      <Divider className="stigg-checkout-plan-header-divider" sx={{ marginY: 2 }} />

      <ContentContainer item>
        <Skeleton width={120} height={20} />

        <FlexedSkeletonContainer $gap={16} marginTop={3}>
          <Skeleton height={20} />
          <Skeleton height={20} />
        </FlexedSkeletonContainer>

        <SkeletonsContainer $gap={130} marginTop={3}>
          <FlexedSkeleton flex={1}>
            <Skeleton height={20} />
          </FlexedSkeleton>

          <FlexedSkeleton flex={3}>
            <Skeleton height={20} />
          </FlexedSkeleton>
        </SkeletonsContainer>
      </ContentContainer>
    </ContentContainer>
  );
}
