import styled from '@emotion/styled/macro';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import { CustomerPortalEntitlement } from '@stigg/js-client-sdk';
import Divider from '@mui/material/Divider';
import Color from 'color';
import { getUsagePercentage, getUsageProgressStatus } from './FeatureUsage.helper';

export const StyledLinearProgress = styled(LinearProgress, { shouldForwardProp: (prop) => !prop.startsWith('$') })<{
  $progressStatus: 'standard' | 'warning' | 'error';
}>`
  height: 4px;
  background-color: ${({ theme }) => Color(theme.stigg.palette.primary).alpha(0.4).string()};

  span {
    background-color: ${({ $progressStatus, theme }) => {
      switch ($progressStatus) {
        case 'standard':
          return theme.stigg.palette.primary;
        case 'warning':
          return theme.stigg.palette.warning;
        case 'error':
          return theme.stigg.palette.error;
        default:
          return theme.stigg.palette.primary;
      }
    }};
  }
`;

export const StyledDivider = styled(Divider)`
  border-style: dotted;
  border-bottom-width: 2px;
  border-color: ${({ theme }) => Color(theme.stigg.palette.outlinedRestingBorder).alpha(0.4).string()};
`;

export type FeatureUsageProgressBarProps = {
  entitlement: CustomerPortalEntitlement;
};

export function FeatureUsageProgressBar({ entitlement }: FeatureUsageProgressBarProps) {
  if (entitlement.hasUnlimitedUsage) {
    return <StyledDivider />;
  }

  const usagePercentage = getUsagePercentage(entitlement.currentUsage || 0, entitlement.usageLimit!);
  const linearProgressStatus = getUsageProgressStatus(usagePercentage);

  return (
    <StyledLinearProgress
      $progressStatus={linearProgressStatus}
      className={`stigg-feature-usage-progress-bar-${linearProgressStatus.toString()}`}
      variant="determinate"
      value={usagePercentage}
    />
  );
}
