import styled from '@emotion/styled/macro';
import Grid from '@mui/material/Grid';

import { Button } from '../../components';

export const CheckoutAddonsContainer = styled(Grid)`
  width: 100%;
  gap: 8px;
`;

export const AddonListItemContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
`;

export const TrashButton = styled(Button)`
  min-width: unset;
  width: 41px;
  height: 41px;
  padding: 0;
`;
