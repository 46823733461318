import styled from '@emotion/styled/macro';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SkeletonLayout = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SkeletonLayoutLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export function SubscriptionOverviewLoader() {
  return (
    <SkeletonLayout className="stigg-subscription-overview-skeleton-layout">
      <SkeletonLayoutLeft>
        <Skeleton width={120} />
        <Skeleton width={120} />
      </SkeletonLayoutLeft>
      <Skeleton width={295} height={193} />
    </SkeletonLayout>
  );
}
