export function calculateUnitQuantityText(
  minUnitQuantity?: number | null,
  maxUnitQuantity?: number | null,
  unitsPlural?: string | null,
) {
  const unitsText = unitsPlural ? ` ${unitsPlural}` : '';

  // copy for only maxunit
  if ((!minUnitQuantity || minUnitQuantity === 1) && maxUnitQuantity) {
    return `Up to ${maxUnitQuantity}${unitsText}`;
  }

  // both limits
  if (minUnitQuantity && minUnitQuantity > 1 && maxUnitQuantity) {
    return `${minUnitQuantity}-${maxUnitQuantity}${unitsText}`;
  }

  if (minUnitQuantity && minUnitQuantity > 1) {
    return `Minimum ${minUnitQuantity}${unitsText}`;
  }

  return '';
}
