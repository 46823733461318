import { useCheckoutContext } from '../CheckoutProvider';

export type WidgetState = {
  readOnly?: boolean;
  isLoadingCheckoutData?: boolean;
  isValid?: boolean;
};

function useCheckoutState() {
  const [{ checkout, widgetState, checkoutLocalization }] = useCheckoutContext();
  return { checkoutState: checkout, widgetState, checkoutLocalization };
}

function useSetWidgetReadonly() {
  const [, setState] = useCheckoutContext();

  return (readOnly: boolean) =>
    setState((draft) => {
      draft.widgetState.readOnly = readOnly;
    });
}

function useSetIsValid() {
  const [, setState] = useCheckoutContext();

  return (isValid: boolean) =>
    setState((draft) => {
      draft.widgetState.isValid = isValid;
    });
}

export function useCheckoutModel() {
  const { checkoutState, widgetState, checkoutLocalization } = useCheckoutState();

  return {
    checkoutState,
    widgetState,
    checkoutLocalization,
    setWidgetReadOnly: useSetWidgetReadonly(),
    setIsValid: useSetIsValid(),
  };
}
