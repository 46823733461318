import styled from '@emotion/styled/macro';
import MuiButton from '@mui/material/Button';

export const StyledButton = styled(MuiButton)`
  border-radius: 10px;
  padding: 8px 16px;
  text-transform: none;

  &,
  &:hover {
    border-color: ${({ theme }) => theme.stigg.palette.outlinedRestingBorder};
  }
`;
