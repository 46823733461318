import { partition } from 'lodash';
import React from 'react';
import { FontWeight } from 'styled-typography';

import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import { BillingPeriod, Plan } from '@stigg/js-client-sdk';

import { Typography } from '../../../common/Typography';
import { formatBillingPeriod } from '../../formatting';
import { usePlanStepModel } from '../../hooks/usePlanStepModel';
import { BillingPeriodButton, BillingPeriodOptions, BillingPeriodPickerContainer } from './BillingPeriodPicker.style';
import { CheckoutLocalization } from '../../configurations/textOverrides';

const BillingPeriodOption = ({
  billingPeriod,
  isOnlyBillingPeriod,
}: {
  billingPeriod: BillingPeriod;
  isOnlyBillingPeriod: boolean;
}) => {
  const { billingPeriod: selectedBillingPeriod, setBillingPeriod } = usePlanStepModel();
  const isActive = selectedBillingPeriod === billingPeriod;

  return (
    <BillingPeriodButton
      onClick={() => setBillingPeriod(billingPeriod)}
      $isActive={isActive}
      $isOnlyBillingPeriod={isOnlyBillingPeriod}>
      <Radio
        checked={isActive}
        onChange={() => setBillingPeriod(billingPeriod)}
        value={billingPeriod}
        disabled={isOnlyBillingPeriod}
        inputProps={{ 'aria-label': formatBillingPeriod(billingPeriod) }}
        sx={{ padding: 0, marginRight: '8px' }}
      />

      <Box>
        <Typography variant="h3" color="primary">
          {formatBillingPeriod(billingPeriod)}
        </Typography>
      </Box>
    </BillingPeriodButton>
  );
};

type BillingPeriodPickerProps = {
  plan?: Plan;
  checkoutLocalization: CheckoutLocalization;
};

export function BillingPeriodPicker({ plan, checkoutLocalization }: BillingPeriodPickerProps) {
  const [monthlyPrices, annualPrices] = partition(
    plan?.pricePoints,
    (price) => price.billingPeriod === BillingPeriod.Monthly,
  );

  const hasBothBillingPeriods = !!monthlyPrices?.length && !!annualPrices?.length;
  if (!hasBothBillingPeriods) {
    return null;
  }

  return (
    <BillingPeriodPickerContainer>
      <Typography variant="h6" color="primary" fontWeight={FontWeight.Medium}>
        {checkoutLocalization.billingPeriodsTitle}
      </Typography>

      <BillingPeriodOptions>
        {!!monthlyPrices?.length && (
          <BillingPeriodOption
            key={BillingPeriod.Monthly}
            billingPeriod={BillingPeriod.Monthly}
            isOnlyBillingPeriod={!hasBothBillingPeriods}
          />
        )}
        {!!annualPrices?.length && (
          <BillingPeriodOption
            key={BillingPeriod.Annually}
            billingPeriod={BillingPeriod.Annually}
            isOnlyBillingPeriod={!hasBothBillingPeriods}
          />
        )}
      </BillingPeriodOptions>
    </BillingPeriodPickerContainer>
  );
}
