import styled from '@emotion/styled/macro';
import React from 'react';
import { CustomerPortalSubscription, SubscriptionStatus } from '@stigg/js-client-sdk';
import Link from '@mui/material/Link';
import SandClockIcon from '../../../../assets/sand-clock.svg';
import { Typography } from '../../../common/Typography';
import { LongText } from '../../../common/LongText';
import { OnManageClick } from '../../CustomerPortalContainer';
import { CustomerPortalIntentionType } from '../../types';

const OnTrialBadge = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: ${({ theme }) => theme.stigg.palette.warning};
  border-radius: 10px;
  padding: 8px 16px;
  margin-top: 16px;
`;

const StyledLink = styled(Link)`
  text-decoration-color: white;
  color: white;
  cursor: pointer;
`;

export function TrialPanel({
  subscription,
  includePlanName,
  onManageSubscription,
}: {
  subscription: CustomerPortalSubscription | undefined;
  includePlanName: boolean;
  onManageSubscription: OnManageClick;
}) {
  if (subscription?.status !== SubscriptionStatus.InTrial) {
    return null;
  }

  return (
    <OnTrialBadge className="stigg-subscription-trial-badge">
      <SandClockIcon className="stigg-subscription-trial-badge-image" />

      <div style={{ flex: 1, marginRight: 4 }}>
        <LongText variant="body1" className="stigg-subscription-trial-badge-text" color="white">
          {`${subscription.trialRemainingDays} days remaining in trial`}
          {includePlanName && ` of the ${subscription.planName} plan`}
        </LongText>
      </div>

      <StyledLink
        className="stigg-subscription-trial-upgrade-button"
        onClick={() => onManageSubscription({ intentionType: CustomerPortalIntentionType.UPGRADE_TRIAL_PLAN })}>
        <Typography color="white">Upgrade plan</Typography>
      </StyledLink>
    </OnTrialBadge>
  );
}
