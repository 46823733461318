import React from 'react';
import isNil from 'lodash/isNil';
import { Price, PriceTierFragment } from '@stigg/js-client-sdk';
import { Typography } from '../../../common/Typography';
import { calculateTierPriceGraduated } from '../../../utils/priceTierUtils';
import { formatPricePerUnit } from './getPriceBreakdownString';
import { LineItemContainer, LineItemRow } from './LineItems';
import { numberFormatter } from '../../../utils/numberUtils';

function getLabel(tiers: PriceTierFragment[], index: number): string {
  const { unitPrice, upTo } = tiers[index];
  if (!unitPrice) {
    return '';
  }

  if (index === 0) {
    return `First ${upTo}`;
  }

  const previousTierUpTo = tiers[index - 1].upTo || 0;
  const startUnit = previousTierUpTo + 1;

  return isNil(upTo)
    ? `${numberFormatter(startUnit)} and above`
    : `Next ${numberFormatter(startUnit)} to ${numberFormatter(upTo)}`;
}

export type GraduatedPriceBreakdownProps = {
  price: Price;
  unitQuantity: number;
};

export function GraduatedPriceBreakdown({ price, unitQuantity }: GraduatedPriceBreakdownProps) {
  const tiers = price.tiers || [];

  const { breakdown } = calculateTierPriceGraduated(tiers, unitQuantity);

  if (breakdown.length === 1) {
    return null;
  }

  return (
    <>
      {breakdown.map(({ unitQuantity, amount }, index) => (
        <LineItemContainer>
          <LineItemRow key={index} style={{ alignItems: 'flex-start' }}>
            <Typography variant="body1" color="tertiary" style={{ whiteSpace: 'nowrap' }}>
              {getLabel(tiers, index)}
            </Typography>
            <Typography variant="body1" color="tertiary" style={{ whiteSpace: 'nowrap' }}>
              {formatPricePerUnit({
                quantity: unitQuantity,
                totalAmount: amount,
                currency: price.currency,
                pricingModel: price.pricingModel,
                billingPeriod: price.billingPeriod,
                tiers: price.tiers,
                tiersMode: price.tiersMode,
                blockSize: price.blockSize,
                priceAmount: price.amount,
              })}
            </Typography>
          </LineItemRow>
        </LineItemContainer>
      ))}
    </>
  );
}
