import React from 'react';
import styled from '@emotion/styled/macro';
import Skeleton from 'react-loading-skeleton';
import CheckUrl from '../../assets/check-stigg.svg';

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EntitlementCheckIcon = styled(CheckUrl)`
  margin-right: 16px;
`;

const PlanOfferingSkeleton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 10px;
  padding: ${({ theme }) => theme.stigg.layout.planPadding};
  background-color: ${({ theme }) => theme.stigg.palette.backgroundPaper};
  min-width: ${({ theme }) => theme.stigg.layout.planMinWidth};
  max-width: ${({ theme }) => theme.stigg.layout.planMaxWidth};
  min-height: 450px;
  border: ${({ theme }) => `1px solid ${theme.stigg.palette.outlinedBorder}`};
`;

const PlansContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.stigg.layout.planMargin};
  flex-wrap: wrap;
  justify-content: center;
`;

const EntitlementContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const FooterSkeletonLoader = styled.div`
  margin-top: auto;
`;

const BillingPeriodLoader = styled.div`
  width: 150px;
  margin-bottom: ${({ theme }) => theme.stigg.layout.switchBottomSpacing};
  padding: 12px 12px;
  border-radius: 10px;
  background-color: white;
  border: ${({ theme }) => `1px solid ${theme.stigg.palette.outlinedBorder}`};
`;

function PlanOfferingLoader() {
  return (
    <PlanOfferingSkeleton>
      <Skeleton width={120} borderRadius={4} />
      <Skeleton circle width={40} height={40} />
      <Skeleton style={{ marginBottom: 33 }} width={120} borderRadius={4} />

      <EntitlementContainer>
        <EntitlementCheckIcon />
        <Skeleton width={120} borderRadius={4} />
      </EntitlementContainer>
      <EntitlementContainer>
        <EntitlementCheckIcon />
        <Skeleton width={120} borderRadius={4} />
      </EntitlementContainer>
      <EntitlementContainer>
        <EntitlementCheckIcon />
        <Skeleton width={120} borderRadius={4} />
      </EntitlementContainer>

      <FooterSkeletonLoader>
        <Skeleton height={50} borderRadius={4} />
      </FooterSkeletonLoader>
    </PlanOfferingSkeleton>
  );
}

export function PaywallLoader() {
  return (
    <SkeletonContainer className="stigg-skeleton-loader-container">
      <BillingPeriodLoader className="stigg-skeleton-loader-billing-period">
        <Skeleton borderRadius={4} />
      </BillingPeriodLoader>

      <PlansContainer className="stigg-skeleton-loader-plans-container">
        <PlanOfferingLoader />
        <PlanOfferingLoader />
        <PlanOfferingLoader />
      </PlansContainer>
    </SkeletonContainer>
  );
}
