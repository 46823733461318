import React from 'react';
import styled from '@emotion/styled/macro';
import Grid from '@mui/material/Grid';
import { Icon } from '../../common/Icon';
import { Typography } from '../../common/Typography';
import { Button } from '../components';

const AppliedCouponContainer = styled(Grid)`
  width: auto;
  display: inline-flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  padding: 6px 8px;
  border-radius: ${({ theme }) => theme.stigg.border.radius};
  border: 1px solid ${({ theme }) => theme.stigg.palette.primary};
`;

export const StyledIcon = styled(Icon, { shouldForwardProp: (prop) => prop !== 'style' })<{ $shouldFill?: boolean }>`
  display: flex;

  g {
    stroke: ${({ theme }) => theme.stigg.palette.primary};
  }

  path {
    fill: ${({ theme, $shouldFill }) => ($shouldFill ? theme.stigg.palette.primary : undefined)};
  }
`;

export type AppliedPromotionCodeProps = {
  promotionCode: string;
  onClearPromotionCode: () => void;
};

export const AppliedPromotionCode = ({ promotionCode, onClearPromotionCode }: AppliedPromotionCodeProps) => (
  <AppliedCouponContainer container>
    <Grid item display="flex" gap={1} alignItems="center">
      <StyledIcon icon="Coupon" />
      <Typography variant="body1" color="primary.main" lineHeight="auto">
        {promotionCode}
      </Typography>
    </Grid>
    <Grid item>
      <Button variant="text" sx={{ minWidth: 'unset', padding: 0 }} onClick={onClearPromotionCode}>
        <StyledIcon icon="Close" $shouldFill />
      </Button>
    </Grid>
  </AppliedCouponContainer>
);
