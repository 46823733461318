import styled from '@emotion/styled/macro';
import { STIGG_WATERMARK_CLASSNAME } from '../../common/PoweredByStigg';
import { SectionContainer } from '../common/SectionContainer';

export const CustomerPortalPaywallLayout = styled(SectionContainer)<{
  $backgroundColor: string;
  $borderColor: string;
}>`
  position: relative;

  .stigg-paywall-layout {
    width: 100%;
  }

  .stigg-paywall-plans-layout {
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: auto;
    justify-content: unset;
    padding: 10px 0px 0px 0px;
  }

  .stigg-paywall-plans-layout .stigg-plan-offering-container:first-of-type {
    margin-left: auto;
  }
  .stigg-paywall-plans-layout .stigg-plan-offering-container:last-child {
    margin-right: auto;
  }

  .${STIGG_WATERMARK_CLASSNAME} {
    display: none;
  }
`;
