import React from 'react';
import { CustomerPortalSubscription } from '@stigg/js-client-sdk';
import { CustomerPortalProvider } from './CustomerPortalProvider';
import { CustomerPortalContainer } from './CustomerPortalContainer';
import { DeepPartial } from '../../types';
import { CustomerPortalLocalization } from './customerPortalTextOverrides';
import { CustomerPortalTheme } from './customerPortalTheme';
import { FilterEntitlementsFn, OnBuyMoreCallbackFn, OnManageSubscriptionFn } from './types';

export type CustomerPortalSection =
  | 'usage'
  | 'addons'
  | 'promotionalEntitlements'
  | 'billingInformation'
  | 'paymentDetails'
  | 'invoices';

export type CustomerPortalProps = {
  onManageSubscription?: OnManageSubscriptionFn;
  onBuyMore?: OnBuyMoreCallbackFn;
  onCancelScheduledUpdates?: (subscription: CustomerPortalSubscription) => Promise<void> | void;
  onContactSupport?: () => Promise<void> | void;
  paywallComponent?: React.ReactNode;
  hiddenSections?: CustomerPortalSection[];
  textOverrides?: DeepPartial<CustomerPortalLocalization>;
  theme?: DeepPartial<CustomerPortalTheme>;
  filterEntitlements?: FilterEntitlementsFn;
  resourceId?: string;
  productId?: string;
};

export function CustomerPortal({ textOverrides, theme, resourceId, productId, ...restProps }: CustomerPortalProps) {
  return (
    <CustomerPortalProvider textOverrides={textOverrides} theme={theme} resourceId={resourceId} productId={productId}>
      <CustomerPortalContainer {...restProps} />
    </CustomerPortalProvider>
  );
}
