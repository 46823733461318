import { BillingPeriod, Plan, Subscription, SubscriptionStatus } from '@stigg/js-client-sdk';
import { countBy, uniq } from 'lodash';

export function computeBillingPeriods(
  plans: Plan[],
  activeSubscriptions?: Subscription[] | null,
  preferredBillingPeriod?: BillingPeriod,
): { defaultBillingPeriod: BillingPeriod; availableBillingPeriods: BillingPeriod[] } {
  const billingPeriods = plans.flatMap((x) => x.pricePoints).map((x) => x.billingPeriod);
  const counts = countBy(billingPeriods);

  const availableBillingPeriods = uniq(billingPeriods);
  let defaultBillingPeriod: BillingPeriod | undefined;

  if (preferredBillingPeriod && availableBillingPeriods.includes(preferredBillingPeriod)) {
    defaultBillingPeriod = preferredBillingPeriod;
  } else if (activeSubscriptions) {
    const activeSubscription = activeSubscriptions.find((x) => x.status === SubscriptionStatus.Active);

    if (activeSubscription && activeSubscription.prices.length > 0) {
      defaultBillingPeriod = activeSubscription?.prices[0].billingPeriod;
    }
  }

  if (!defaultBillingPeriod) {
    defaultBillingPeriod =
      (counts[BillingPeriod.Monthly] || 0) > (counts[BillingPeriod.Annually] || 0)
        ? BillingPeriod.Monthly
        : BillingPeriod.Annually;
  }

  return { defaultBillingPeriod, availableBillingPeriods };
}
