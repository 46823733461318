import React from 'react';
import { CustomerPortalSubscription } from '@stigg/js-client-sdk';
import { Typography } from '../../../common/Typography';
import { TrialPanel } from './TrialPanel';
import { CustomerPortalTheme } from '../../customerPortalTheme';
import { OnManageClick } from '../../CustomerPortalContainer';

type SubscriptionViewProps = {
  mainSubscription: CustomerPortalSubscription;
  trialSubscription: CustomerPortalSubscription | undefined;
  onManageSubscription: OnManageClick;
  theme: CustomerPortalTheme;
};

export function SubscriptionView({
  mainSubscription,
  trialSubscription,
  onManageSubscription,
  theme,
}: SubscriptionViewProps) {
  return (
    <div className="stigg-subscription-view-layout">
      <Typography
        className="stigg-subscription-plan-name"
        variant="h1"
        overrideColor={theme.planNameColor}
        style={{ marginTop: 8 }}>
        {mainSubscription.planName} plan
      </Typography>

      <TrialPanel
        subscription={trialSubscription}
        includePlanName={trialSubscription?.planName !== mainSubscription.planName}
        onManageSubscription={onManageSubscription}
      />
    </div>
  );
}
