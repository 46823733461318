import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { Typography } from '../../common/Typography';
import { CheckoutLocalization } from '../configurations/textOverrides';

export const ChangePlanButton = ({
  onClick,
  checkoutLocalization,
  size,
}: {
  onClick: () => void;
  checkoutLocalization: CheckoutLocalization;
  size: ButtonProps['size'];
}) => {
  return (
    <Button
      className="stigg-checkout-change-plan-button"
      sx={{ textTransform: 'none' }}
      variant="text"
      size={size}
      onClick={onClick}>
      <Typography
        className="stigg-checkout-change-plan-button-text"
        color="primary.main"
        variant="h3"
        style={{ lineHeight: '24px' }}>
        {checkoutLocalization.changePlan}
      </Typography>
    </Button>
  );
};
