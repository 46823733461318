import React from 'react';
import { CustomerPortalTheme } from '../customerPortalTheme';
import { CustomerPortalPaywallLayout } from './CustomerPortalPaywall.style';
import { SectionTitle } from '../common/SectionTitle';
import { SectionHeader } from '../common/SectionHeader';

type CustomerPortalPaywallProps = {
  paywallComponent?: React.ReactNode;
  theme: CustomerPortalTheme;
  title: string;
  isLoading: boolean;
};

export const CustomerPortalPaywall = React.forwardRef<HTMLDivElement, CustomerPortalPaywallProps>(
  ({ paywallComponent, theme, title, isLoading }, ref) => {
    if (!paywallComponent) {
      return null;
    }

    return (
      <CustomerPortalPaywallLayout
        className="stigg-customer-portal-paywall-section"
        ref={ref}
        $backgroundColor={theme.backgroundColor}
        $borderColor={theme.borderColor}>
        <SectionHeader className="stigg-customer-portal-paywall-header">
          <SectionTitle isLoading={isLoading} className="stigg-customer-portal-paywall-section-title" title={title} />
        </SectionHeader>

        {paywallComponent}
      </CustomerPortalPaywallLayout>
    );
  },
);
