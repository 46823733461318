import styled from '@emotion/styled/macro';

export const CustomerPortalLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & * {
    box-sizing: border-box;
  }
`;

export const CustomerPortalSections = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
`;
