import { BillingPeriod, Currency } from '@stigg/js-client-sdk';
import moment from 'moment';
import React from 'react';
import { currencyPriceFormatter } from '../../../utils/currencyUtils';
import { Typography } from '../../../common/Typography';

export type NextEstimatedBillProps = {
  billingPeriod: BillingPeriod;
  rangeEnd: string;
  amount: number;
  currency: Currency;
};

export function NextEstimatedBill({ billingPeriod, rangeEnd, amount, currency }: NextEstimatedBillProps) {
  return (
    <>
      <Typography variant="h6">Next estimated bill</Typography>
      <Typography variant="h1">{currencyPriceFormatter({ amount, currency, maximumFractionDigits: 2 })}</Typography>
      <Typography as="div" variant="body1" color="secondary">
        Billed {billingPeriod === BillingPeriod.Monthly ? 'monthly' : 'annually'} on{' '}
        <Typography variant="body1" span color="primary">
          {moment.utc(rangeEnd).format('MMM Do, YYYY')}
        </Typography>
      </Typography>
      <Typography variant="body1" color="secondary">
        * Additional tax rates may apply
      </Typography>
    </>
  );
}
