import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { Typography } from '../../../common/Typography';
import { useCustomerPortalContext } from '../../CustomerPortalProvider';

export function AddonsList() {
  const { customerPortal } = useCustomerPortalContext();
  const addons = flatMap(customerPortal?.subscriptions, (subscription) => subscription.addons);
  if (isEmpty(addons)) {
    return null;
  }

  return (
    <div className="stigg-addons-list-layout">
      {addons.map((addon, index) => (
        <Typography key={addon.addonId} span className="stigg-addon-list-item-text" variant="body1">
          {addon.quantity > 1 && `${addon.quantity} X `}
          {addon.displayName}
          {index === addons.length - 1 ? '' : ', '}
        </Typography>
      ))}
    </div>
  );
}
