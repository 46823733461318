import styled from '@emotion/styled/macro';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Color from 'color';

export const BillingPeriodPickerContainer = styled(Box)`
  margin: 16px 0;
`;

export const BillingPeriodButton = styled.button<{
  $isActive?: boolean;
  $disabled?: boolean;
  $isOnlyBillingPeriod?: boolean;
}>`
  cursor: ${({ $disabled, $isOnlyBillingPeriod }) =>
    $disabled ? 'default' : $isOnlyBillingPeriod ? 'default' : 'pointer'};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 8px;
  border-radius: 10px;
  border: ${({ theme, $isActive, $isOnlyBillingPeriod }) => {
    let borderColor = theme.stigg.palette.outlinedBorder;
    if ($isOnlyBillingPeriod) {
      borderColor = 'transparent';
    } else if ($isActive) {
      borderColor = theme.stigg.palette.outlinedRestingBorder;
    }
    return `1px solid ${borderColor}`;
  }};
  background: ${({ theme, $isActive, $isOnlyBillingPeriod }) => {
    if ($isOnlyBillingPeriod) {
      return 'transparent';
    }
    if ($isActive) {
      return Color(theme.stigg.palette.primary).alpha(0.15).toString();
    }
    return 'transparent';
  }};
  text-transform: none;
  text-align: start;
  height: 36px;

  &.MuiButton-root {
    padding: 0 16px 0 8px;
    &:hover {
      background: none;
    }
  }
`;

export const BillingPeriodOptions = styled(Box)`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`;

export const BillingPeriodPrice = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
`;

export const DiscountChip = styled(Chip)`
  & .MuiChip-label {
    font-size: 12px;
  }
`;
