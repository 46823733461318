import React, { useState } from 'react';

import styled from '@emotion/styled/macro';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import { Icon } from '../../common/Icon';
import { Typography } from '../../common/Typography';
import { Button, InputField } from '../components';
import { usePreviewSubscriptionAction } from '../hooks';
import { usePromotionCodeModel } from '../hooks/useCouponModel';
import { AddPromotionCodeButton } from './AddPromotionCodeButton';
import { PromotionCodeSectionProps } from './PromotionCodeSection';

const CouponCodeAddButton = styled(Button)`
  padding: 4px 10px;
  min-width: unset;
  border-radius: ${({ theme }) => theme.stigg.border.radius};
  display: flex;
  align-items: center;
`;

export const AddPromotionCode = ({
  disabled,
  checkoutLocalization,
  onMockCheckoutPreview,
}: PromotionCodeSectionProps) => {
  const { setPromotionCode: persistPromotionCode } = usePromotionCodeModel();
  const [showInput, setShowInput] = useState(false);
  const [promotionCode, setPromotionCode] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const { previewSubscriptionAction } = usePreviewSubscriptionAction({ onMockCheckoutPreview });

  const handlePromotionCode = async () => {
    setIsLoading(true);
    setErrorMessage('');

    const { subscriptionPreview, errorMessage } = await previewSubscriptionAction({ promotionCode });

    if (!errorMessage && subscriptionPreview?.immediateInvoice?.discountDetails) {
      persistPromotionCode(promotionCode.toUpperCase());
      setShowInput(false);
    } else if (errorMessage) {
      setErrorMessage(errorMessage);
    }
    setIsLoading(false);
  };

  if (!showInput) {
    return (
      <AddPromotionCodeButton
        disabled={disabled}
        onAddClick={() => setShowInput(true)}
        checkoutLocalization={checkoutLocalization}
      />
    );
  }

  return (
    <Grid>
      <Typography variant="body1" color={errorMessage ? 'error' : disabled ? 'disabled' : 'primary.main'}>
        {checkoutLocalization.summary.couponCodeTitle}
      </Typography>

      <InputField
        disabled={disabled}
        autoFocus
        variant="outlined"
        fullWidth
        error={!!errorMessage}
        value={promotionCode}
        onChange={(e) => {
          setPromotionCode(e.target.value);
        }}
        inputProps={{ maxLength: 20 }}
        onKeyDown={(e: any) => {
          if (e.key === 'Enter') {
            void handlePromotionCode();
            e.preventDefault();
          }
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          endAdornment: (
            <CouponCodeAddButton
              variant="contained"
              disabled={disabled}
              onClick={() => {
                void handlePromotionCode();
              }}>
              {isLoading ? (
                <CircularProgress size={18} sx={{ color: 'white' }} />
              ) : (
                <Icon style={{ display: 'flex' }} icon="ArrowForward" />
              )}
            </CouponCodeAddButton>
          ),
        }}
      />
      {!!errorMessage && (
        <Typography variant="body1" color="error">
          {errorMessage}
        </Typography>
      )}
    </Grid>
  );
};
