import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Typography } from '../../common/Typography';
import { useCustomerPortalContext } from '../CustomerPortalProvider';

export type SectionTitleProps = {
  title: string;
  className: string;
  isLoading?: boolean;
};

export function SectionTitle({ title, className, isLoading }: SectionTitleProps) {
  const { theme } = useCustomerPortalContext();

  if (isLoading) {
    return <Skeleton width={120} />;
  }

  return (
    <Typography className={className} overrideColor={theme.sectionTitleColor} variant="h3" bold>
      {title}
    </Typography>
  );
}
