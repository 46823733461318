import { useCheckoutContext } from '../CheckoutProvider';

export type PromotionCodeState = {
  promotionCode?: string;
};

function usePromotionCodeState() {
  const [{ promotionCode }] = useCheckoutContext();
  return promotionCode;
}

function useSetPromotionCode() {
  const [, setState] = useCheckoutContext();

  return (promotionCode: string) =>
    setState((draft) => {
      draft.promotionCode = promotionCode;
    });
}

export function usePromotionCodeModel() {
  const promotionCode = usePromotionCodeState();

  return {
    promotionCode,
    setPromotionCode: useSetPromotionCode(),
  };
}
