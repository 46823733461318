import React from 'react';
import styled from '@emotion/styled/macro';
import { PoweredByStigg } from '../common/PoweredByStigg';

const CustomerPortalHeaderLayout = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 24px;
`;

export function CustomerPortalHeader({ showWatermark }: { showWatermark?: boolean }) {
  return (
    <CustomerPortalHeaderLayout className="stigg-customer-portal-header-layout">
      <PoweredByStigg source="customer_portal" showWatermark={showWatermark} />
    </CustomerPortalHeaderLayout>
  );
}
