import styled from '@emotion/styled/macro';

export const FeatureUsageContainer = styled.div<{ $highlight: boolean; $borderColor: string }>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border: ${({ $borderColor }) => `1px solid ${$borderColor}`};
  border-radius: 10px;
  row-gap: 16px;
  height: 100%;

  ${({ theme, $highlight }) => $highlight && `background-color: ${theme.stigg.palette.backgroundSection}`};
`;
