import { BillingModel, CustomerPortalEntitlement, CustomerPortalSubscription } from '@stigg/js-client-sdk';
import React from 'react';
import { keyBy } from 'lodash';
import { ChargeItem } from './ChargeItem';
import { OnBuyMoreCallbackFn } from '../../types';
import { OnManageClick } from '../../CustomerPortalContainer';

export type ChargesProps = {
  subscription: CustomerPortalSubscription;
  entitlements: CustomerPortalEntitlement[];
  onBuyMore?: OnBuyMoreCallbackFn;
  onManageSubscription: OnManageClick;
  canUpgradeSubscription: boolean;
  hasCustomSubscription: boolean;
};

export function ChargeList({
  subscription,
  entitlements,
  onBuyMore,
  onManageSubscription,
  hasCustomSubscription,
  canUpgradeSubscription,
}: ChargesProps) {
  const entitlementByFeature = keyBy(entitlements, 'feature.refId');

  const filteredSubscriptionPrices = subscription.prices.filter(
    ({ feature, price }) => !!feature && entitlementByFeature[feature.refId] && !!price,
  );

  if (!filteredSubscriptionPrices.length) {
    return null;
  }

  return (
    <div className="stigg-charge-list" style={{ display: 'flex', flexDirection: 'column', gap: 16, marginTop: 32 }}>
      {filteredSubscriptionPrices.map(({ feature, price, billingModel }) => {
        if (!feature || !price) {
          return null;
        }

        const entitlement = entitlementByFeature[feature.refId];

        if (!entitlement || !entitlement.feature) {
          return null;
        }

        return (
          <ChargeItem
            key={feature.refId}
            entitlement={entitlement}
            feature={entitlement.feature}
            amount={price.amount}
            currency={price.currency}
            hideChargePrice={billingModel === BillingModel.PerUnit}
            onBuyMore={onBuyMore}
            onManageSubscription={onManageSubscription}
            canUpgradeSubscription={canUpgradeSubscription}
            hasCustomSubscription={hasCustomSubscription}
          />
        );
      })}
    </div>
  );
}
